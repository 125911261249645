import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Popup } from '@/components/Popup/Popup';
import { PROMOCODE_TYPE_FRIEND } from '@/const/promocode';
import { Plus } from '@/icons/Plus';
import { MailingRewardsList } from './MailingRewardsList';
import { MailingRewardsMenu } from './MailingRewardsMenu';
import { MailingRewardsPanels, } from './MailingRewardsPanels';
import styles from './styles.module.scss';
export const MailingRewardsInput = memo(({ value, onChange, offers = [], promocodes: allPromocodes = [], giftCards = [], shouldRenderPanels = true, currency, buttonRef, }) => {
    var _a;
    const { t } = useTranslation();
    const triggerRef = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState('');
    const promocodes = allPromocodes.filter((i) => i.codeType !== PROMOCODE_TYPE_FRIEND);
    const friendPromocodes = allPromocodes.filter((i) => i.codeType === PROMOCODE_TYPE_FRIEND);
    const togglePopup = () => {
        setPopupIsOpen((popupIsOpen) => !popupIsOpen);
        setActiveMenuItem('');
    };
    const handleChangeActiveMenuItem = (item) => {
        if (item === 'bonuses') {
            onChange(Object.assign(Object.assign({}, value), { bonusesAmount: '', bonusesLifetime: '' }));
            togglePopup();
            return;
        }
        setActiveMenuItem(item);
    };
    const handleSelectListItem = (itemId) => {
        if (activeMenuItem === 'offers')
            onChange(Object.assign(Object.assign({}, value), { offers: [...(value.offers || []), { id: itemId, expireInDays: '' }] }));
        if (activeMenuItem === 'promocodes' ||
            activeMenuItem === 'friendPromocodes')
            onChange(Object.assign(Object.assign({}, value), { promocodeId: itemId }));
        if (activeMenuItem === 'giftCards')
            onChange(Object.assign(Object.assign({}, value), { giftCardId: itemId }));
        togglePopup();
    };
    let listTitle = '';
    let listItems = [];
    let disabledItemIds = [];
    if (activeMenuItem === 'offers') {
        listTitle = 'Выберите акцию';
        listItems = offers;
        disabledItemIds = ((_a = value.offers) === null || _a === void 0 ? void 0 : _a.map((o) => o.id)) || [];
    }
    if (activeMenuItem === 'promocodes' ||
        activeMenuItem === 'friendPromocodes') {
        listTitle = 'Выберите промокод';
        listItems =
            activeMenuItem === 'promocodes' ? promocodes : friendPromocodes;
        disabledItemIds =
            typeof value.promocodeId !== 'undefined' ? [value.promocodeId] : [];
    }
    if (activeMenuItem === 'giftCards') {
        listTitle = 'Выберите карту';
        listItems = giftCards;
        disabledItemIds =
            typeof value.giftCardId !== 'undefined' ? [value.giftCardId] : [];
    }
    return (_jsxs(_Fragment, { children: [shouldRenderPanels && (_jsx(MailingRewardsPanels, { value: value, onChange: onChange, offers: offers, promocodes: allPromocodes, giftCards: giftCards, currency: currency })), _jsx("span", Object.assign({ ref: triggerRef, onClick: togglePopup }, { children: _jsx(Button, { ref: buttonRef, type: 'button', theme: 'primary', icon: _jsx(Plus, {}), isActive: popupIsOpen }) })), _jsx(Popup, Object.assign({ isOpen: popupIsOpen, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, onClose: togglePopup, placement: 'bottom-start', offset: [0, 10], isRounded: true }, { children: popupIsOpen && (_jsxs("div", Object.assign({ className: styles.popupWrapper }, { children: [_jsx("div", Object.assign({ className: styles.menuWrapper }, { children: _jsx(MailingRewardsMenu, { activeItem: activeMenuItem, onChangeActiveItem: handleChangeActiveMenuItem, offersCount: offers.length, promocodesCount: promocodes.length, friendPromocodesCount: friendPromocodes.length, giftCardsCount: giftCards.length, hasBonusesApplied: typeof value.bonusesAmount !== 'undefined' }) })), _jsx("div", Object.assign({ className: styles.listWrapper }, { children: activeMenuItem ? (_jsx(MailingRewardsList, { title: t(listTitle), items: listItems, onItemClick: handleSelectListItem, disabledItemIds: disabledItemIds, currency: currency }, activeMenuItem)) : (_jsx("div", Object.assign({ className: styles.listEmpty }, { children: t('Выберите тип поощрения слева') }))) }))] }))) }))] }));
});
