import { createChannelNodes } from '@/components/flow/nodes/ChannelNode/utils';
import { createScheduleNodes } from '@/components/flow/nodes/ConditionsNode/schedule';
import { createRecipientFiltersNodes, createTriggerConditionNodes, } from '@/components/flow/nodes/ConditionsNode/utils';
import { createDelayNodes } from '@/components/flow/nodes/DelayNode/utils';
import { createEmailReceivedNodes } from '@/components/flow/nodes/EmailReceived/utils';
import { createLoopNodes } from '@/components/flow/nodes/LoopNode/utils';
import { createTriggerNode } from '@/components/flow/nodes/TriggerNode/utils';
import { TRIGGER_TYPE_EMAIL_DELIVERED, TRIGGER_TYPE_SCHEDULE, } from '@/components/MailingTriggerTypeInput/useOptions';
export const composeMailings = (allMailings) => {
    const nodes = [];
    const edges = [];
    const mailings = allMailings.filter((m) => Number(m.triggerType) !== TRIGGER_TYPE_EMAIL_DELIVERED);
    const emailMailings = allMailings.filter((m) => m.channels.email);
    const emailReceivedMailings = allMailings.filter((m) => Number(m.triggerType) === TRIGGER_TYPE_EMAIL_DELIVERED);
    if (!mailings.length)
        return { nodes, edges };
    // 1) Create Trigger Node
    const triggerType = Number(mailings[0].triggerType);
    nodes.push(createTriggerNode(triggerType));
    const triggerBranches = { trigger: { 'no-handle': mailings } };
    // 2) Create Loop Nodes
    const { loopNodes, loopEdges, loopBranches } = createLoopNodes(triggerBranches, triggerType);
    nodes.push(...loopNodes);
    edges.push(...loopEdges);
    // 3) Create "Before" Delay Nodes
    const { delayNodes: beforeDelayNodes, delayEdges: beforeDelayEdges, delayBranches: beforeDelayBranches, } = createDelayNodes(loopBranches, true);
    nodes.push(...beforeDelayNodes);
    edges.push(...beforeDelayEdges);
    // 4) Create Trigger Condition Nodes
    const { triggerConditionNodes, triggerConditionEdges, triggerConditionBranches, } = triggerType === TRIGGER_TYPE_SCHEDULE
        ? createScheduleNodes(beforeDelayBranches)
        : createTriggerConditionNodes(beforeDelayBranches);
    nodes.push(...triggerConditionNodes);
    edges.push(...triggerConditionEdges);
    // 5) Create "Non-Before" Delay Nodes
    const { delayNodes: nonBeforeDelayNodes, delayEdges: nonBeforeDelayEdges, delayBranches: nonBeforeDelayBranches, } = createDelayNodes(triggerConditionBranches);
    nodes.push(...nonBeforeDelayNodes);
    edges.push(...nonBeforeDelayEdges);
    // 6) Create Recipient Filters Nodes
    const { recipientFiltersNodes, recipientFiltersEdges, recipientFiltersBranches, } = createRecipientFiltersNodes(nonBeforeDelayBranches);
    nodes.push(...recipientFiltersNodes);
    edges.push(...recipientFiltersEdges);
    // 7) Create Channel Nodes
    const { channelNodes, channelEdges } = createChannelNodes(recipientFiltersBranches, emailReceivedMailings);
    nodes.push(...channelNodes);
    edges.push(...channelEdges);
    // 8) Create Sub-Graphs for Email Received Mailings
    for (let i = 0; i < emailMailings.length; i += 1) {
        const emailMailing = emailMailings[i];
        const mailings = emailReceivedMailings.filter((m) => { var _a; return ((_a = m.triggerConditions) === null || _a === void 0 ? void 0 : _a.conditions.mailingBrandEq) === emailMailing.id; });
        if (!mailings.length)
            continue;
        // 8.1) Create Delay Nodes
        const { delayNodes, delayEdges, delayBranches } = createDelayNodes({
            [`email-${emailMailing.id}`]: { 'no-handle': mailings },
        });
        nodes.push(...delayNodes);
        edges.push(...delayEdges);
        // 8.2) Create Email Received Condition Nodes
        const { emailReceivedNodes, emailReceivedEdges, emailReceivedBranches } = createEmailReceivedNodes(delayBranches);
        nodes.push(...emailReceivedNodes);
        edges.push(...emailReceivedEdges);
        // 8.3) Create Recipient Filters Nodes
        const { recipientFiltersNodes, recipientFiltersEdges, recipientFiltersBranches, } = createRecipientFiltersNodes(emailReceivedBranches);
        nodes.push(...recipientFiltersNodes);
        edges.push(...recipientFiltersEdges);
        // 8.4) Create Channel Nodes
        const { channelNodes, channelEdges } = createChannelNodes(recipientFiltersBranches, emailReceivedMailings);
        nodes.push(...channelNodes);
        edges.push(...channelEdges);
    }
    return { nodes, edges };
};
