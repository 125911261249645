import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import styles from './styles.module.scss';
export const Promocode = memo(({ code, isPersonal = false, isDark = false, truncateCodeAt }) => {
    const codeTruncated = !!truncateCodeAt && code.length > truncateCodeAt
        ? code.slice(0, truncateCodeAt) + '...'
        : code;
    const formatCode = (code) => (isPersonal ? `${code}-XXX-XXX` : code);
    return (_jsxs("span", Object.assign({ className: classNames(styles.promocode, {
            [styles.dark]: isDark,
        }), title: codeTruncated !== code ? formatCode(code) : '' }, { children: [_jsxs("svg", Object.assign({ className: styles.label, width: '11', height: '21', viewBox: '0 0 11 21', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { className: styles.svgFill, d: 'M4.17647 1C2.42215 1 1 2.27599 1 3.85V6.225C1 7.01201 1.71108 7.65 2.58823 7.65H2.66572C4.42004 7.65 5.84219 8.92599 5.84219 10.5C5.84219 12.074 4.42004 13.35 2.66572 13.35H2.58824C1.71108 13.35 1 13.988 1 14.775V17.15C1 18.724 2.42215 20 4.17647 20H10V1H4.17647Z' }), _jsx("path", { className: styles.svgStroke, d: 'M10 1H4.17647C2.42215 1 1 2.27599 1 3.85V6.225C1 7.01201 1.71108 7.65 2.58823 7.65H2.66572C4.42004 7.65 5.84219 8.92599 5.84219 10.5C5.84219 12.074 4.42004 13.35 2.66572 13.35H2.58824C1.71108 13.35 1 13.988 1 14.775V17.15C1 18.724 2.42215 20 4.17647 20H10', strokeWidth: '2' }), _jsx("path", { className: styles.svgStroke, d: 'M10 1L10 20', strokeWidth: '2', strokeDasharray: '2 2' })] })), formatCode(codeTruncated)] })));
});
