import { t } from 'i18next';
import styles from '../styles.module.scss';
import { checkingForTargetKey } from './checkingForTargetKey';
const cellWidth = { бренд: 107, размер: 87, цвет: 88 };
export const createHeadItems = (items) => {
    const columns = [
        {
            id: 'number',
            title: '№',
            align: 'center',
            width: 27,
        },
        {
            id: 'name',
            title: t('Наименование'),
            align: 'left',
            width: 250,
        },
    ];
    const attributeItems = (items === null || items === void 0 ? void 0 : items.reduce((acc, { product = {} }) => {
        var _a;
        (_a = product === null || product === void 0 ? void 0 : product.properties) === null || _a === void 0 ? void 0 : _a.forEach(({ id, title }) => {
            if (checkingForTargetKey(title)) {
                const templObj = {
                    id,
                    title,
                    align: 'center',
                    width: cellWidth[title.toLocaleLowerCase()],
                };
                acc[title.toLowerCase()] = templObj;
            }
        });
        for (const key in product === null || product === void 0 ? void 0 : product.attributes)
            if (checkingForTargetKey(key)) {
                const templObj = {
                    id: key,
                    title: key,
                    align: 'center',
                    width: cellWidth[key.toLocaleLowerCase()],
                };
                acc[key.toLowerCase()] = templObj;
            }
        return acc;
    }, {})) || {};
    if (attributeItems['бренд'])
        columns.push(attributeItems['бренд']);
    if (attributeItems['размер'])
        columns.push(attributeItems['размер']);
    if (attributeItems['цвет'])
        columns.push(attributeItems['цвет']);
    columns.push({
        id: 'price',
        title: t('Цена'),
        align: 'right',
        width: 102,
        className: styles.nameCellHead,
    });
    return columns;
};
