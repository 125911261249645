export const createEmailReceivedNode = (mailing) => {
    var _a;
    const { ifMailing } = ((_a = mailing.triggerConditions) === null || _a === void 0 ? void 0 : _a.conditions) || {};
    const node = {
        id: `email-received-${mailing.id}`,
        type: 'email-received',
        data: { type: ifMailing },
        position: { x: 0, y: 0 },
    };
    return node;
};
export const createEmailReceivedNodes = (branches) => {
    var _a;
    const emailReceivedNodes = [];
    const emailReceivedEdges = [];
    const emailReceivedBranches = {};
    for (const fromNodeId of Object.keys(branches)) {
        const handles = branches[fromNodeId];
        for (const fromHandleId of Object.keys(handles)) {
            const mailings = handles[fromHandleId];
            const nodeMap = {};
            for (let i = 0; i < mailings.length; i += 1) {
                const mailing = mailings[i];
                const { ifMailing } = ((_a = mailing.triggerConditions) === null || _a === void 0 ? void 0 : _a.conditions) || {};
                let node = nodeMap[ifMailing];
                if (!node) {
                    node = createEmailReceivedNode(mailing);
                    nodeMap[ifMailing] = node;
                    emailReceivedNodes.push(node);
                    emailReceivedEdges.push({
                        id: `from-${fromNodeId}-to-${node.id}`,
                        source: fromNodeId,
                        sourceHandle: fromHandleId !== 'no-handle' ? fromHandleId : undefined,
                        target: node.id,
                    });
                }
                if (!emailReceivedBranches[node.id])
                    emailReceivedBranches[node.id] = {
                        no: [],
                    };
                emailReceivedBranches[node.id].no.push(mailing);
            }
        }
    }
    return { emailReceivedNodes, emailReceivedEdges, emailReceivedBranches };
};
