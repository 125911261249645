import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccentLink } from '@/components/AccentText/AccentLink';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { numberRound } from '@/utils/numbers';
export const FormPointOfSale = memo(({ id, onSubmit, setHasFormChanged, defaultValues = {}, timezoneList = [], productCatalogList = [], }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors, isSubmitting, dirtyFields }, } = useForm({
        defaultValues,
    });
    useEffect(() => {
        if (setHasFormChanged)
            setHasFormChanged(!isSubmitting && !!Object.keys(dirtyFields).length);
    }, [setHasFormChanged, isSubmitting, dirtyFields]);
    const codeHelp = (_jsxs(_Fragment, { children: [t('Уникальный для каждого магазина.'), ' ', _jsx(AccentLink, Object.assign({ href: 'https://help.maxma.com/p/zlNyFs--sadEob/Nastroiki', target: '_blank', tabIndex: -1, isSmall: true }, { children: t('Подробнее') }))] }));
    const validateTrimValue = (value) => !value.trim() ? t('FIELD_REQUIRED_ERROR') : true;
    return (_jsxs("form", Object.assign({ id: id, onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Код'), labelFor: 'code', controlSize: '150', help: ((_a = errors.code) === null || _a === void 0 ? void 0 : _a.message) || codeHelp, isRequired: true, hasError: !!errors.code }, { children: _jsx(Controller, { name: 'code', rules: {
                        maxLength: {
                            value: 200,
                            message: t('FIELD_MAX_LENGTH_ERROR', {
                                amount: numberRound(200),
                                count: 200,
                            }),
                        },
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                        validate: validateTrimValue,
                    }, control: control, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'code', placeholder: t('введите код'), hasError: !!errors.code, autoFocus: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Название'), labelFor: 'name', controlSize: '300', help: (_b = errors.name) === null || _b === void 0 ? void 0 : _b.message, isRequired: true, hasError: !!errors.name }, { children: _jsx(Controller, { name: 'name', rules: {
                        maxLength: {
                            value: 200,
                            message: t('FIELD_MAX_LENGTH_ERROR', {
                                amount: numberRound(200),
                                count: 200,
                            }),
                        },
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                        validate: validateTrimValue,
                    }, control: control, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'name', placeholder: t('введите название точки'), hasError: !!errors.name }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Регион'), labelFor: 'timezone', controlSize: '300' }, { children: _jsx(Controller, { name: 'timezone', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'timezone', placeholder: t('выберите регион'), value: field.value || null, onChange: field.onChange, options: timezoneList, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.region }))) }) })), !!(productCatalogList === null || productCatalogList === void 0 ? void 0 : productCatalogList.length) && (_jsx(FormFieldRow, Object.assign({ label: t('PRODUCT_CATALOG_FORM_LABEL'), labelFor: 'productCatalog', controlSize: '300' }, { children: _jsx(Controller, { name: 'productCatalog', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'productCatalog', placeholder: t('выберите каталог'), value: field.value || null, onChange: field.onChange, options: productCatalogList, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.name, asSelect: true }))) }) })))] })));
});
