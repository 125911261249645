import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Trash } from '@/icons/Trash';
import styles from './styles.module.scss';
export const TrashButton = memo(({ disabled = false, tooltip, onClick }) => {
    const { t } = useTranslation();
    return (_jsx(Tooltip, Object.assign({ title: tooltip || t('Удалить'), placement: 'top' }, { children: _jsx("button", Object.assign({ disabled: disabled, className: classNames(styles.button, { [styles.disabled]: disabled }), type: 'button', onClick: onClick }, { children: _jsx(Trash, {}) })) })));
});
