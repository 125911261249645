import { jsx as _jsx } from "react/jsx-runtime";
import { useGetShopListQuery } from '@/api/query/shop';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { ShopsTable } from './ShopsTable';
export const Shops = () => {
    const { user } = useCurrentUser();
    const { data = [], isLoading, isFetching } = useGetShopListQuery();
    if (!user)
        return null;
    return (_jsx(Container, Object.assign({ notMobileRightPadding: true }, { children: _jsx(ShopsTable, { user: user, isLoading: isLoading, isFetching: isFetching, shops: data }) })));
};
