import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position } from '@xyflow/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { RepeatOneTime } from '@/icons/flow/RepeatOneTime';
import styles from './styles.module.scss';
export const LoopNode = ({ selected }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ className: styles.tooltip, title: t('Ветка сработает один раз на клиента'), PopperProps: { disablePortal: true } }, { children: _jsxs("div", Object.assign({ className: classNames(styles.wrapper, {
                        [styles.selected]: selected,
                    }) }, { children: [_jsx(RepeatOneTime, {}), _jsx("span", Object.assign({ className: styles.content }, { children: t('1 раз') }))] })) })), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target', 'flow-handle-target-delay'), type: 'target', position: Position.Left }), _jsx(Handle, { className: 'flow-handle', type: 'source', position: Position.Right })] }));
};
