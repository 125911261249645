import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from '@/api/query';
import { USER_LOGOUT } from '@/store/actions/userLogout';
import appPeriodSlice from '@/store/slice/appPeriod';
import billingSlice from '@/store/slice/billing';
import filtersSlice from '@/store/slice/filters';
import headerSlice from '@/store/slice/header';
import httpErrorSlice from '@/store/slice/httpError';
import mailingsSlice from '@/store/slice/mailings';
import productAttributesSlice from '@/store/slice/productAttributes';
import reportsSlice from '@/store/slice/reports';
import sidebarSlice from '@/store/slice/sidebar';
import walletSlice from '@/store/slice/wallet';
const appReducer = combineReducers({
    filters: filtersSlice,
    productAttributes: productAttributesSlice,
    appPeriod: appPeriodSlice,
    reports: reportsSlice,
    mailings: mailingsSlice,
    billing: billingSlice,
    header: headerSlice,
    sidebar: sidebarSlice,
    httpError: httpErrorSlice,
    wallet: walletSlice,
    [api.reducerPath]: api.reducer,
});
const getRootInitialState = (state, action) => {
    return {
        filters: filtersSlice(state.filters, action),
        productAttributes: productAttributesSlice(state.productAttributes, action),
        appPeriod: appPeriodSlice(state.appPeriod, action),
        reports: reportsSlice(state.reports, action),
        mailings: mailingsSlice(state.mailings, action),
        billing: billingSlice(state.billing, action),
        header: headerSlice(state.header, action),
        sidebar: sidebarSlice(state.sidebar, action),
        httpError: httpErrorSlice(state.httpError, action),
        wallet: walletSlice(state.wallet, action),
        [api.reducerPath]: api.reducer(state.api, action),
    };
};
const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
        const initialState = getRootInitialState({ httpError: { errors: [], ignore401: true } }, action);
        return appReducer(initialState, action);
    }
    return appReducer(state, action);
};
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(api.middleware),
});
setupListeners(store.dispatch);
