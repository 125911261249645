import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { AccordionTable } from '@/components/AccordionTable/AccordionTable';
import { TotalRowStructure } from './TotalRowStructure';
import { createHeadItems } from './utils/createHeadItems';
import { tableRows } from './utils/tableRows';
export const StructureTab = memo(({ purchase, currency }) => {
    const headItems = createHeadItems();
    return (_jsx(AccordionTable, { headItems: headItems, rows: tableRows(currency, purchase), AdditionalRow: _jsx(TotalRowStructure, { totalAmount: purchase === null || purchase === void 0 ? void 0 : purchase.totalAmount, bonusesCollected: purchase === null || purchase === void 0 ? void 0 : purchase.bonusesCollected, currency: currency, totalDiscount: purchase === null || purchase === void 0 ? void 0 : purchase.totalDiscount, paidAmount: purchase === null || purchase === void 0 ? void 0 : purchase.paidAmount }) }));
});
