import { t } from 'i18next';
import { generatePath } from 'react-router-dom';
import { createHref, ROUTES } from '@/app/pages/routes';
import { money } from '@/utils/numbers';
import styles from '../styles.module.scss';
export const createDiscountList = (item, purchase, currency) => {
    var _a, _b, _c;
    const path = generatePath(ROUTES.PROMOCODES_EDIT, {
        promocodeId: ((_a = purchase === null || purchase === void 0 ? void 0 : purchase.promocode) === null || _a === void 0 ? void 0 : _a.id.toString()) || '',
    });
    const href = createHref(path);
    const offers = item === null || item === void 0 ? void 0 : item.offers.map(({ offerId, title, bonusesCollected, amount, isDeleted }) => {
        const path = generatePath(ROUTES.OFFERS_EDIT, {
            offerId: String(offerId),
        });
        const href = createHref(path);
        // Моежет быть либо бонус либо скидка
        const value = +amount
            ? money({
                input: amount,
                withFraction: true,
                removeFractionZeros: true,
                currency,
            })
            : `+ ${t('BONUSES_AMOUNT', {
                amount: bonusesCollected,
                count: bonusesCollected,
            })}`;
        return {
            title,
            value: String(value),
            link: isDeleted ? undefined : href,
            className: bonusesCollected && styles.bonusCell,
        };
    });
    let list = [];
    if (purchase === null || purchase === void 0 ? void 0 : purchase.promocode) {
        const promocode = {
            title: `${t('Промокод')} ${((_b = purchase === null || purchase === void 0 ? void 0 : purchase.promocode) === null || _b === void 0 ? void 0 : _b.code) || ''}`,
            value: `${money({
                input: item === null || item === void 0 ? void 0 : item.promocodeDiscount,
                withFraction: true,
                removeFractionZeros: true,
                currency,
            })}`,
            link: href,
        };
        list.push(promocode);
    }
    if (purchase === null || purchase === void 0 ? void 0 : purchase.giftCards[0]) {
        const giftCardData = (_c = purchase === null || purchase === void 0 ? void 0 : purchase.giftCards) === null || _c === void 0 ? void 0 : _c[0];
        const path = generatePath(ROUTES.GIFT_CARDS_EDIT, {
            giftCardId: giftCardData.id.toString(),
        });
        const href = createHref(path);
        const giftCard = {
            title: `${t('Подарочная карта')} ${giftCardData.name || ''}`,
            value: `${money({
                input: item.giftCardDiscount,
                withFraction: true,
                removeFractionZeros: true,
                currency,
            })}`,
            link: giftCardData.isDeleted ? undefined : href,
        };
        list.push(giftCard);
    }
    list = [...list, ...offers].sort((a, b) => +(a === null || a === void 0 ? void 0 : a.value) - +(b === null || b === void 0 ? void 0 : b.value));
    if ((item === null || item === void 0 ? void 0 : item.roundingDiscount) && +(item === null || item === void 0 ? void 0 : item.roundingDiscount) > 0) {
        const roundingDiscount = {
            title: t('Округление'),
            value: `${money({
                input: item.roundingDiscount,
                withFraction: true,
                removeFractionZeros: true,
                currency,
            })}`,
        };
        list.push(roundingDiscount);
    }
    if (+(item === null || item === void 0 ? void 0 : item.bonusesDiscount)) {
        const bonusesApplied = {
            title: t('Оплачено бонусами'),
            value: `${t('BONUSES_AMOUNT', {
                amount: +item.bonusesDiscount,
                count: +item.bonusesDiscount,
            })}`,
        };
        list.push(bonusesApplied);
    }
    if (+(item === null || item === void 0 ? void 0 : item.autoDiscount)) {
        const autoDiscount = {
            title: t('Скидка кассы'),
            value: `${money({
                input: item.autoDiscount,
                withFraction: true,
                removeFractionZeros: true,
                currency,
            })}`,
        };
        list.push(autoDiscount);
    }
    if (+(item === null || item === void 0 ? void 0 : item.manualDiscount)) {
        const manualDiscount = {
            title: t('Ручная скидка'),
            value: `${money({
                input: item.manualDiscount,
                withFraction: true,
                removeFractionZeros: true,
                currency,
            })}`,
        };
        list.push(manualDiscount);
    }
    return list;
};
