var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { InputButton } from '@/components/Input/InputButton';
import { getEmailDraftKey } from '@/utils/getEmailDraftKey';
import { getFromLS, removeFromLS } from '@/utils/LS';
import styles from './styles.module.scss';
export const EmailEditorButton = ({ children, design, hasPreview, hasError, mailingId, brandGlobalKey, onOpenTemplateModal, onOpenEditorModal, isDisabled, }) => {
    const { t } = useTranslation();
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const draftKey = getEmailDraftKey(mailingId, brandGlobalKey);
        const draftDesign = getFromLS({ key: draftKey });
        if (draftDesign && draftDesign !== design) {
            const title = t('У вас остался несохраненный дизайн. Восстановить его?');
            const agreeLabel = t('Восстановить');
            const disagreeLabel = t('Нет');
            const result = yield confirm({
                confirmation: title,
                agreeLabel,
                agreeButtonSize: 'large',
                disagreeLabel,
                isDanger: false,
                isDangerCancel: true,
                proceedClose: true,
            });
            if (result === 'close')
                return;
            if (result) {
                onOpenEditorModal(draftDesign);
                return;
            }
            removeFromLS({ key: draftKey });
        }
        if (!design)
            onOpenTemplateModal();
        else
            onOpenEditorModal(design);
    });
    return (_jsx(InputButton, Object.assign({ wrapperClassName: styles.buttonWrapper, className: classNames(styles.button, {
            [styles.hasPreview]: hasPreview,
        }), type: 'button', hasError: hasError, onClick: handleClick, disabled: isDisabled }, { children: children })));
};
