import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiltersInput } from '@/components/FiltersPanel/FiltersInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { TRIGGER_TYPE_SEGMENT_JOIN, TRIGGER_TYPE_SEGMENT_LEAVE, } from '@/components/MailingTriggerTypeInput/useOptions';
import { useTriggerFilters } from '@/services/filters/data/useTriggerFilters';
import { OFFSET_SIGN_SCHEDULE } from './FormMailingOffset';
export const FormMailingTriggerFilters = ({ labelWidth }) => {
    const { t } = useTranslation();
    const { control, formState, setValue, getValues, watch } = useFormContext();
    const { errors, isSubmitting, isSubmitted } = formState;
    const offsetSign = watch('offsetSign');
    const triggerType = watch('triggerType') || TRIGGER_TYPE_SEGMENT_JOIN;
    const filtersTree = useTriggerFilters(triggerType);
    const isRequired = triggerType === TRIGGER_TYPE_SEGMENT_JOIN ||
        triggerType === TRIGGER_TYPE_SEGMENT_LEAVE;
    const validate = (value) => {
        if (!isRequired)
            return true;
        const { triggerType } = getValues();
        const filters = (value && value[triggerType || 0]) || [];
        const isValid = filters.length > 0;
        if ((isSubmitting || isSubmitted) && !isValid)
            return 'error';
        return true;
    };
    const handleUpdate = (value) => {
        const { triggerType, triggerFilters } = getValues();
        const type = triggerType || 0;
        const filters = triggerFilters || {};
        const newValue = Object.assign(Object.assign({}, filters), { [type]: value });
        setValue('triggerFilters', newValue, { shouldValidate: true });
    };
    if (offsetSign === OFFSET_SIGN_SCHEDULE)
        return null;
    return filtersTree.length > 0 ? (_jsx(FormFieldRow, Object.assign({ label: t('если'), labelWidth: labelWidth, help: errors.triggerFilters ? t('Задайте необходимые критерии') : undefined, hasError: !!errors.triggerFilters, isRequired: isRequired }, { children: _jsx(Controller, { name: 'triggerFilters', control: control, rules: { validate }, render: ({ field }) => {
                var _a, _b, _c;
                return (_jsx(FiltersInput, { filtersTree: filtersTree, value: ((_a = field.value) === null || _a === void 0 ? void 0 : _a[triggerType]) || [], onUpdate: handleUpdate, hasHangingOperator: false, hasResetButton: (((_c = (_b = field.value) === null || _b === void 0 ? void 0 : _b[triggerType]) === null || _c === void 0 ? void 0 : _c.length) || 0) > 1, buttonRef: field.ref }));
            } }) }))) : null;
};
