import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { useDeleteShop } from '@/forms/FormPointOfSale/useDeleteShop';
import styles from './styles.module.scss';
export const useTableData = ({ shops, onEdit }) => {
    const { t } = useTranslation();
    const deleteShop = useDeleteShop();
    const userHasAccessTo = useUserPermissions();
    const userHasShopManage = userHasAccessTo([
        'SHOPS_OWN_MANAGE',
        'SHOPS_MANAGE',
    ]);
    const columns = useMemo(() => [
        { id: 'name', title: t('Название'), description: t('Код') },
        { id: 'region', title: t('Регион') },
        { id: 'tz', title: t('Часовой пояс') },
        { id: 'createdAt', title: t('Дата добавления') },
        { id: 'actions' },
    ].filter((i) => i !== null), [t]);
    const rows = useMemo(() => {
        return shops.map((shop) => {
            var _a, _b;
            return ({
                id: shop.id,
                cells: [
                    _jsxs(React.Fragment, { children: [shop.name, _jsx("div", Object.assign({ className: styles.description }, { children: shop.code }))] }, shop.id),
                    (_a = shop.timezone) === null || _a === void 0 ? void 0 : _a.region,
                    (_b = shop.timezone) === null || _b === void 0 ? void 0 : _b.tz,
                    moment(shop.createdAt).format('DD.MM.YYYY'),
                ],
                actions: [
                    {
                        id: 'edit',
                        tooltip: !userHasShopManage && t('Недоступно для вашей роли'),
                        label: t('Редактировать'),
                        onClick: () => onEdit(shop),
                        isDisabled: !userHasShopManage,
                    },
                    {
                        id: 'delete',
                        tooltip: !userHasShopManage && t('Недоступно для вашей роли'),
                        label: t('Удалить'),
                        onClick: () => {
                            deleteShop(shop.id, shop.name);
                        },
                        isDanger: true,
                        isDisabled: !userHasShopManage,
                    },
                ],
            });
        });
    }, [shops, onEdit, t]);
    return { columns: columns, rows: rows };
};
