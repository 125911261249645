import { useCallback } from 'react';
import { useGetPurchaseCountQuery, useGetPurchaseListQuery, } from '@/api/query/purchases';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, paginationSelector, } from '@/store/selectors/appPeriod';
import { changeOffset } from '@/store/slice/appPeriod';
export const useData = ({ filters, searchText, }) => {
    const dispatch = useAppDispatch();
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const { purchases: { limit, offset }, } = useAppSelector(paginationSelector);
    const setOffset = useCallback((offset) => dispatch(changeOffset({ list: 'purchases', offset })), []);
    const { data: count, isLoading: countIsLoading, isFetching: countIsFetching, refetch: refetchCount, } = useGetPurchaseCountQuery({ filters, searchText, periodStart, periodEnd }, { refetchOnMountOrArgChange: true });
    const { data, isLoading: dataIsLoading, isFetching: dataIsFetching, refetch: refetchPurchases, } = useGetPurchaseListQuery({ filters, searchText, periodStart, periodEnd, limit, offset }, { refetchOnMountOrArgChange: true });
    const isLoading = countIsLoading || dataIsLoading;
    const isFetching = countIsFetching || dataIsFetching;
    return {
        count: typeof count === 'undefined' || typeof count === 'number'
            ? count
            : count.purchase,
        data,
        isLoading,
        isFetching,
        limit,
        offset,
        setOffset,
        refetchCount,
        refetchPurchases,
    };
};
