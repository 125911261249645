import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_LOW, TextNode } from 'lexical';
import { useEffect } from 'react';
import { INSERT_VARIABLE, insertVariable, insertVariableText, } from './insertVariable';
import transformTextNodeToVariable from './transformTextNodeToVariable';
import { VariableNode } from './VariableNode';
export const VariablePlugin = ({ variables, method = 'transform', }) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerCommand(INSERT_VARIABLE, method === 'transform' ? insertVariableText : insertVariable, COMMAND_PRIORITY_LOW);
    }, [editor]);
    useEffect(() => {
        if (method === 'transform')
            return editor.registerNodeTransform(TextNode, transformTextNodeToVariable(variables));
    }, [editor]);
    return null;
};
export { VariableNode };
