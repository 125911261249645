import * as brandApi from '@/api/brandApi';
import * as mailingApi from '@/api/mailingApi';
import * as offerApi from '@/api/offerApi';
import * as promocodeApi from '@/api/promocodeApi';
import * as shopApi from '@/api/shopApi';
import { createSimpleThunk } from '@/utils/thunks';
export const getShopOptionsThunk = createSimpleThunk('filters/get-shop-options', shopApi.getShopOptionsApi);
export const getClientLevelOptionsThunk = createSimpleThunk('filters/get-client-level-options', brandApi.getClientLevelListApi);
export const getPromocodeFriendOptionsThunk = createSimpleThunk('filters/get-promocode-friend-options', promocodeApi.getPromocodeFriendOptionListApi);
export const getPromocodeOptionsThunk = createSimpleThunk('filters/get-promocode-options', promocodeApi.getPromocodeOptionListApi);
export const getAutomaticMailingOptionsThunk = createSimpleThunk('filters/get-automatic-mailing-options', mailingApi.getAutomaticMailingOptionListApi);
export const getManualMailingOptionsThunk = createSimpleThunk('filters/get-manual-mailing-options', mailingApi.getManualMailingOptionListApi);
export const getOfferOptionsThunk = createSimpleThunk('filters/get-offer-options', offerApi.getOfferOptionListApi);
export const getCashierOptionsThunk = createSimpleThunk('filters/get-cashier-options', brandApi.getCashierListApi);
export const getCategoryOptionsThunk = createSimpleThunk('filters/get-category-options', brandApi.getCategoryListApi);
export const getProductsTreeThunk = createSimpleThunk('filters/get-products-tree', brandApi.getProductsTreeApi);
export const getProductsBranchThunk = createSimpleThunk('filters/get-products-branch', brandApi.getProductsBranchApi);
export const getBrandExtraFieldOptionsThunk = createSimpleThunk('filters/get-brand-extra-field-options', brandApi.getBrandExtraFieldOptionsApi);
export const getProductOptionsThunk = createSimpleThunk('filters/get-product-options', brandApi.getProductListApi);
export const getSegmentOptionsThunk = createSimpleThunk('filters/get-segment-options', brandApi.getSegmentListApi);
export const getRFMSegmentOptionsThunk = createSimpleThunk('filters/get-rfm-segment-options', brandApi.getRFMSegmentListApi);
export const getProductPropertiesThunk = createSimpleThunk('filters/get-product-properties', brandApi.getProductProperties);
export const getProductAttributesKeysThunk = createSimpleThunk('filters/get-product-attributes-keys', brandApi.getProductAttributesKeys);
export const getProductAttributesValuesThunk = createSimpleThunk('filters/get-product-attributes-values', brandApi.getProductAttributesValues);
