var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ImageCropInput } from '@/components/ImageCropInput/ImageCropInput';
import { Input } from '@/components/Input/Input';
import { WalletCardFormSection } from '@/components/wallet-cards/WalletCardFormSection/WalletCardFormSection';
import { useAppSelector } from '@/hooks/store';
import { publishButtonClickedSelector } from '@/store/selectors/wallet';
import { capitalize } from '@/utils/capitalize';
import { scaleImage } from '@/utils/scaleImage';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { ImageHelp } from '../../helpers/ImageHelp';
import styles from '../../styles.module.scss';
export const Notify = () => {
    const { t } = useTranslation();
    const { setActiveCardType, markReadyToSave, uploadImage, notify, setNotify, isElementClick, setIsElementClick, } = useWalletCardForm();
    const shouldDisplayErrors = useAppSelector(publishButtonClickedSelector);
    const hasTitleError = shouldDisplayErrors && !notify.title.trim();
    const hasLogoError = shouldDisplayErrors && !notify.logo;
    const handleFocus = () => {
        setActiveCardType('notify');
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(false);
    };
    const handleChange = (values) => {
        setNotify((notify) => (Object.assign(Object.assign({}, notify), values)));
    };
    const handleApplyLogo = (logo) => __awaiter(void 0, void 0, void 0, function* () {
        handleChange({ logo, logoLoading: !!logo, logoRejectReason: '' });
        if (!logo) {
            markReadyToSave();
            return;
        }
        try {
            const logoSmall = yield scaleImage(logo);
            const [logoUploadResponse, logoSmallUploadResponse] = yield Promise.all([
                uploadImage === null || uploadImage === void 0 ? void 0 : uploadImage(logo),
                uploadImage === null || uploadImage === void 0 ? void 0 : uploadImage(logoSmall),
            ]);
            handleChange({ logoUploadResponse, logoSmallUploadResponse });
        }
        catch (e) {
            // do nothing
        }
        finally {
            handleChange({ logoLoading: false });
            markReadyToSave();
        }
    });
    return (_jsx(WalletCardFormSection, { children: _jsxs("div", Object.assign({ className: styles.notifyWrapper }, { children: [_jsxs("div", Object.assign({ className: classNames(styles.control, styles.logo) }, { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'notify-logo' }, { children: capitalize(t('логотип')) + '*' })), _jsxs("div", Object.assign({ className: styles.uploadWrapper }, { children: [_jsx(ImageCropInput, { id: 'notify-logo', value: notify.logo, onChange: handleApplyLogo, onReject: (reason) => {
                                        handleChange({ logo: null, logoRejectReason: reason || '' });
                                    }, placeholder: 'image', previewWidth: 48, previewHeight: 48, constraints: {
                                        mimeType: 'image/*',
                                        maxSize: 10000000,
                                        dimensions: { width: 200, height: 200 },
                                    }, hasError: hasLogoError || !!notify.logoRejectReason, cropModalTitle: capitalize(t('логотип')), onFocus: handleFocus }), _jsx(ImageHelp, { dimensions: '200\u00D7200', help: hasLogoError ? t('обязательное поле') : t('от') + ' 200×200', rejectReason: notify.logoRejectReason, isLoading: notify.logoLoading, isError: hasLogoError, isFloating: true })] }))] })), _jsxs("div", Object.assign({ className: classNames(styles.control, styles.notifyTitleInput) }, { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'notify-title' }, { children: t('Название бренда') + '*' })), _jsxs("div", { children: [_jsx(Input, { id: 'notify-title', value: notify.title, onChange: (e) => {
                                        handleChange({ title: e.target.value });
                                    }, onFocus: handleFocus, onBlur: markReadyToSave, autoFocus: !isElementClick, hasError: hasTitleError }), hasTitleError && (_jsx("div", Object.assign({ className: classNames(styles.description, styles.error) }, { children: t('обязательное поле') })))] })] }))] })) }));
};
