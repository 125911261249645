import { jsx as _jsx } from "react/jsx-runtime";
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { RenameFlowForm } from './RenameFlowForm';
import { useAddFlow } from './useAddFlow';
export const CreateFlowForm = ({ triggerType, mailings, }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { createFlow, isCreatingFlow } = useAddFlow({
        onSuccess: (id) => {
            var _a;
            const path = generatePath(ROUTES.FLOWS_EDIT, { flowId: id.toString() });
            navigate(path, {
                state: { mailingIds: (_a = location.state) === null || _a === void 0 ? void 0 : _a.mailingIds },
            });
        },
    });
    const handleSubmit = (title) => {
        createFlow(title, triggerType, mailings.filter((m) => m.triggerType === triggerType).map((m) => m.id));
    };
    const handleCancel = () => {
        var _a;
        if (((_a = location.state) === null || _a === void 0 ? void 0 : _a.from) === 'auto-mailings-list')
            navigate(ROUTES.MAILINGS_AUTOMATIC);
        else
            navigate(ROUTES.FLOWS);
    };
    return (_jsx(RenameFlowForm, { onSubmit: handleSubmit, onCancel: handleCancel, disabled: isCreatingFlow }));
};
