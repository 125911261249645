import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Modal } from '@/components/Modal/Modal';
import { useDashboardMetricGroups } from '@/hooks/useDashboardMetricGroups';
import { ReportMetricModalItem } from './ReportMetricModalItem';
import { ReportMetricModalSection } from './ReportMetricModalSection';
import styles from './styles.module.scss';
export const ReportMetricModal = memo(({ isOpen, onClose, value: defaultValue, onApply }) => {
    const { t, i18n } = useTranslation();
    const metricGroups = useDashboardMetricGroups();
    const [value, setValue] = useState(defaultValue);
    const isMulti = Array.isArray(value);
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue.toString()]);
    const handleChangeValue = useCallback((metricId) => {
        if (isMulti)
            value.includes(metricId)
                ? setValue(value.filter((id) => id !== metricId))
                : setValue([...value, metricId]);
        else
            value === metricId ? setValue('') : setValue(metricId);
    }, [value]);
    const handleReset = useCallback(() => {
        if (isMulti)
            setValue([]);
    }, [isMulti]);
    const handleApply = useCallback(() => {
        onApply(value);
        setValue(defaultValue);
    }, [onApply, value.toString(), defaultValue.toString()]);
    const handleClose = useCallback(() => {
        onClose();
        setValue(defaultValue);
    }, [onClose, defaultValue.toString()]);
    const footer = (_jsxs("div", Object.assign({ className: styles.footer }, { children: [isMulti && (_jsx(Button, Object.assign({ onClick: handleReset, disabled: !value.length, isOutlined: true }, { children: t('Сбросить') }))), _jsx(Button, Object.assign({ onClick: handleApply, disabled: !isMulti && !value, theme: 'primary', size: 'large' }, { children: t('Применить') }))] })));
    return (_jsx(Modal, Object.assign({ title: t('Метрики'), isOpen: isOpen, onClose: handleClose, footer: footer, size: 'large', hasNoPadding: true }, { children: _jsx("div", Object.assign({ className: classNames(styles.content, {
                [styles.sinlge]: !isMulti,
                [styles.en]: i18n.language === 'en',
            }) }, { children: metricGroups.map((group) => (_jsxs("div", Object.assign({ className: styles.group }, { children: [_jsx("div", Object.assign({ className: styles.groupTitle }, { children: group.label })), group.items.map((item) => 'items' in item ? (_jsx(ReportMetricModalSection, Object.assign({ title: item.label, circles: isMulti
                            ? item.items
                                .filter((metric) => value.includes(metric.id))
                                .map((metric) => metric.color)
                            : item.items.find((metric) => metric.id === value)
                                ? ['single']
                                : [] }, { children: item.items.map((metric) => (_jsx(ReportMetricModalItem, { metric: metric, isSelected: isMulti
                                ? value.includes(metric.id)
                                : value === metric.id, onClick: () => {
                                handleChangeValue(metric.id);
                            } }, metric.id))) }), item.id)) : (_jsx(ReportMetricModalItem, { metric: item, isSelected: isMulti ? value.includes(item.id) : value === item.id, onClick: () => {
                            handleChangeValue(item.id);
                        } }, item.id)))] }), group.id))) })) })));
});
