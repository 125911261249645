import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import './styles.scss';
import { Controls, ReactFlow, ReactFlowProvider, useEdgesState, useNodesState, } from '@xyflow/react';
import classNames from 'classnames';
import { nodeTypes } from '../nodes/nodeTypes';
import styles from './styles.module.scss';
import { useGraphLayout } from './useGraphLayout';
export const FlowCanvas = ({ nodes: initialNodes = [], edges: initialEdges = [], fitView = false, onNodeClick, }) => {
    const [nodes, , handleNodesChange] = useNodesState(initialNodes);
    const [edges, , handleEdgesChange] = useEdgesState(initialEdges);
    const layoutIsComplete = useGraphLayout({
        fitView,
        interactive: !!onNodeClick,
    });
    return (_jsx(ReactFlow, Object.assign({ className: classNames(styles.canvas, {
            [styles.complete]: layoutIsComplete,
        }), nodeTypes: nodeTypes, nodes: nodes, edges: edges, onNodesChange: handleNodesChange, onEdgesChange: handleEdgesChange, nodesConnectable: false, onNodeClick: onNodeClick }, { children: _jsx(Controls, { showInteractive: false }) })));
};
export const FlowCanvasProvider = ({ children }) => {
    return _jsx(ReactFlowProvider, { children: children });
};
