import moment from 'moment';
export const formatDate = (date) => {
    return date ? moment(date).format('DD.MM.YYYY HH:mm') : '';
};
export const formatDay = (day, locale, isShort) => {
    return moment()
        .locale(locale)
        .set('day', day)
        .format(isShort ? 'dd' : 'dddd')
        .toLowerCase();
};
