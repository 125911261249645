import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MailingsForm } from '@/app/pages/MailingsForm/MailingsForm';
import { ROUTES } from '@/app/pages/routes';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Drawer } from '@/components/Drawer/Drawer';
import { AutoMailingsFlow } from '@/components/flow/AutoMailingsFlow/AutoMailingsFlow';
import { CreateFlowForm } from './CreateFlowForm';
import { EditFlowForm } from './EditFlowForm';
import styles from './styles.module.scss';
import { useData } from './useData';
import { useGlobalUI } from './useGlobalUI';
export const FlowsForm = () => {
    useGlobalUI();
    const navigate = useNavigate();
    const { brand } = useCurrentUser();
    const { selectedFlow, triggerType, mailings = [], isLoading } = useData();
    const [activeMailing, setActiveMailing] = useState(null);
    useEffect(() => {
        if (!isLoading && !mailings.length)
            navigate(ROUTES.FLOWS);
    }, [isLoading, mailings.length]);
    const handleMailingClick = useCallback((mailing) => {
        setActiveMailing(mailing);
    }, []);
    const handleCloseDrawer = useCallback(() => {
        setActiveMailing(null);
    }, []);
    if (!brand)
        return null;
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [!isLoading && !selectedFlow && (_jsx(CreateFlowForm, { triggerType: triggerType, mailings: mailings })), !!selectedFlow && _jsx(EditFlowForm, { flow: selectedFlow }), !isLoading ? (_jsx(AutoMailingsFlow, { mailings: mailings, onMailingClick: selectedFlow ? handleMailingClick : undefined, fitView: true })) : null, _jsx(Drawer, Object.assign({ open: !!activeMailing, onClose: handleCloseDrawer, anchor: 'left' }, { children: !!activeMailing && (_jsx(MailingsForm, { mailingId: activeMailing.id, onGoBack: handleCloseDrawer, isStandalone: true })) }))] })));
};
