import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Input } from '@/components/Input/Input';
import { normalizeLink } from './normalizeLink';
import styles from './styles.module.scss';
export const LinkPopup = ({ title, link, onChangeTitle, onChangeLink, onChange, onDelete, onClose, }) => {
    const { t } = useTranslation();
    const [titleError, setTitleError] = useState(false);
    const [linkError, setLinkError] = useState(false);
    const handleChange = () => {
        var _a, _b;
        let success = true;
        if (!link.trim() || !normalizeLink(link)) {
            setLinkError(true);
            success = false;
            (_a = document.getElementById('link-form-link')) === null || _a === void 0 ? void 0 : _a.focus();
        }
        if (!title.trim()) {
            setTitleError(true);
            success = false;
            (_b = document.getElementById('link-form-title')) === null || _b === void 0 ? void 0 : _b.focus();
        }
        if (success) {
            onChange(title, normalizeLink(link));
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
    };
    const handleDelete = () => {
        onDelete();
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    const handleInputKeyUp = (e) => {
        if (e.code === 'Enter')
            handleChange();
    };
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsxs("div", Object.assign({ className: styles.form }, { children: [_jsxs("div", Object.assign({ className: styles.control }, { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'link-form-title' }, { children: t('Название') })), _jsx(Input, { id: 'link-form-title', value: title, onChange: (e) => {
                                    onChangeTitle(e.target.value);
                                    setTitleError(false);
                                }, onKeyUp: handleInputKeyUp, hasError: titleError, autoFocus: true })] })), _jsxs("div", Object.assign({ className: styles.control }, { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'link-form-link' }, { children: t('Ссылка') })), _jsx(Input, { id: 'link-form-link', value: link, onChange: (e) => {
                                    onChangeLink(e.target.value);
                                    setLinkError(false);
                                }, onKeyUp: handleInputKeyUp, hasError: linkError })] }))] })), _jsxs("div", Object.assign({ className: styles.footer }, { children: [_jsx(Button, Object.assign({ type: 'button', theme: 'primary', onClick: handleChange }, { children: t('Сохранить') })), _jsx(Button, Object.assign({ type: 'button', theme: 'danger', onClick: handleDelete }, { children: t('Удалить') }))] }))] })));
};
