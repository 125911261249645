import { TextNode } from 'lexical';
import styles from './styles.module.scss';
export class SpaceNode extends TextNode {
    constructor(key) {
        super(' ', key);
    }
    static getType() {
        return 'space';
    }
    static clone(node) {
        return new SpaceNode(node.__key);
    }
    // DOM methods ---------------------------------------------------------------
    createDOM(config) {
        const dom = super.createDOM(config);
        dom.className = styles.space;
        return dom;
    }
    updateDOM() {
        return false;
    }
    // JSON methods --------------------------------------------------------------
    exportJSON() {
        return Object.assign(Object.assign({}, super.exportJSON()), { type: 'space' });
    }
    static importJSON(serializedNode) {
        const node = $createSpaceNode();
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }
}
export function $createSpaceNode() {
    const node = new SpaceNode().setMode('token');
    return node;
}
