import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@/icons/Link';
import { Smile } from '@/icons/Smile';
import { EditorToolbarButton } from './EditorToolbarButton';
import styles from './styles.module.scss';
export const EditorToolbar = ({ emojiPopup, varsPopup, hasSmallVarsPopupIcon = false, linkPopup, linkActive = false, isRounded = false, isAnimated = false, onOpenLinkPopup, onCloseLinkPopup, }) => {
    const { t } = useTranslation();
    const [activePopup, setActivePopup] = useState('');
    const handleClosePopup = () => {
        setActivePopup('');
    };
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, styles.toolbar, {
            [styles.rounded]: isRounded,
            [styles.animated]: isAnimated,
            [styles.active]: !!activePopup,
        }) }, { children: [_jsx(EditorToolbarButton, Object.assign({ popupContent: varsPopup, popupIsOpen: !linkActive && activePopup === 'variables', onOpen: () => setActivePopup('variables'), onClose: handleClosePopup, disabled: linkActive }, { children: hasSmallVarsPopupIcon ? '[…]' : t('Персонализация') })), !!linkPopup && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.divider }), _jsx(EditorToolbarButton, Object.assign({ popupContent: linkPopup, popupIsOpen: activePopup === 'link', onOpen: () => {
                            setActivePopup('link');
                            onOpenLinkPopup === null || onOpenLinkPopup === void 0 ? void 0 : onOpenLinkPopup();
                        }, onClose: () => {
                            handleClosePopup();
                            onCloseLinkPopup === null || onCloseLinkPopup === void 0 ? void 0 : onCloseLinkPopup();
                        } }, { children: _jsx("div", Object.assign({ className: classNames(styles.linkIcon, {
                                [styles.active]: linkActive,
                            }) }, { children: _jsx(Link, {}) })) }))] })), !!emojiPopup && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.divider }), _jsx(EditorToolbarButton, Object.assign({ popupContent: emojiPopup, popupIsOpen: activePopup === 'emoji', onOpen: () => setActivePopup('emoji'), onClose: handleClosePopup }, { children: _jsx(Smile, {}) }))] }))] })));
};
