import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { ImageCropInput } from '@/components/ImageCropInput/ImageCropInput';
import { Input } from '@/components/Input/Input';
import { useAppSelector } from '@/hooks/store';
import { TextAlignLeft } from '@/icons/TextAlignLeft';
import { LogoLarge } from '@/icons/wallet-card/google/LogoLarge';
import { publishButtonClickedSelector } from '@/store/selectors/wallet';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { ImageHelp } from '../../helpers/ImageHelp';
import styles from '../../styles.module.scss';
export const HeadGoogle = () => {
    const { t } = useTranslation();
    const { setActiveCardType, setGoogleCardIsFlipped, setIsElementClick, markReadyToSave, uploadImage, googleHead, setGoogleHead, setAppleHead, } = useWalletCardForm();
    const shouldDisplayErrors = useAppSelector(publishButtonClickedSelector);
    const hasLogoError = shouldDisplayErrors && !googleHead.logo;
    const hasTitleError = shouldDisplayErrors && !googleHead.title;
    const hasProgramNameError = shouldDisplayErrors && !googleHead.programName;
    const [applyBothLogo, setApplyBothLogo] = useState(false);
    const handleFocus = () => {
        setActiveCardType('google');
        setGoogleCardIsFlipped(false);
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(false);
    };
    const handleChange = (head) => {
        setGoogleHead((googleHead) => (Object.assign(Object.assign({}, googleHead), head)));
    };
    const handleChangeAppleLogo = (logo, response) => {
        if (applyBothLogo)
            setAppleHead((head) => (Object.assign(Object.assign({}, head), { logo, logoUploadResponse: response, logoRejectReason: '' })));
        setApplyBothLogo(false);
    };
    const handleApplyLogo = (logo) => {
        handleChange({ logo, logoLoading: !!logo, logoRejectReason: '' });
        if (!logo) {
            markReadyToSave();
            return;
        }
        uploadImage === null || uploadImage === void 0 ? void 0 : uploadImage(logo).then((response) => {
            handleChangeAppleLogo(logo, response);
            handleChange({ logoUploadResponse: response });
        }).finally(() => {
            handleChange({ logoLoading: false });
            markReadyToSave();
        });
    };
    return (_jsxs("div", Object.assign({ className: classNames(styles.headWrapper, styles.google) }, { children: [_jsxs("div", Object.assign({ className: classNames(styles.control, styles.logo) }, { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'google-head-logo' }, { children: t('логотип') + '*' })), _jsxs("div", Object.assign({ className: styles.uploadWrapper }, { children: [_jsx(ImageCropInput, { id: 'google-head-logo', value: googleHead.logo, onChange: handleApplyLogo, onReject: (reason) => {
                                    handleChange({ logo: null, logoRejectReason: reason || '' });
                                }, previewWidth: 48, previewHeight: 48, placeholder: 'image', constraints: {
                                    mimeType: 'image/*',
                                    maxSize: 10000000,
                                    dimensions: { width: 150, height: 150 },
                                }, hasError: hasLogoError || !!googleHead.logoRejectReason, onFocus: handleFocus, cropModalIcon: _jsx(LogoLarge, {}), cropModalTitle: t('Логотип в шапку для Google'), cropModalFooter: _jsx(Checkbox, Object.assign({ checked: applyBothLogo, onChange: (e) => setApplyBothLogo(e.target.checked), theme: 'dark' }, { children: t('и для Apple') })), isRound: true }), _jsx(ImageHelp, { dimensions: '150\u00D7150', help: hasLogoError ? t('обязательное поле') : t('от') + ' 150×150', rejectReason: googleHead.logoRejectReason, isLoading: googleHead.logoLoading, isError: hasLogoError })] }))] })), _jsxs("div", Object.assign({ className: styles.controlsWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.control }, { children: [_jsxs("label", Object.assign({ className: styles.label, htmlFor: 'google-head-title' }, { children: [_jsx(TextAlignLeft, {}), t('заголовок карты') + '*'] })), _jsx(Input, { id: 'google-head-title', value: googleHead.title, onChange: (e) => {
                                    handleChange({ title: e.target.value });
                                }, onFocus: handleFocus, onBlur: markReadyToSave, hasError: hasTitleError })] })), _jsxs("div", Object.assign({ className: styles.control }, { children: [_jsxs("label", Object.assign({ className: styles.label, htmlFor: 'google-head-program-name' }, { children: [_jsx(TextAlignLeft, {}), t('название карты') + '*'] })), _jsx(Input, { id: 'google-head-program-name', value: googleHead.programName, onChange: (e) => {
                                    handleChange({ programName: e.target.value });
                                }, onFocus: handleFocus, onBlur: markReadyToSave, hasError: hasProgramNameError })] }))] }))] })));
};
