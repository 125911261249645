import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Button } from '@/components/Button2/Button';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { DateInput } from '@/components/DateInput/DateInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { Input } from '@/components/Input/Input';
import { TextArea } from '@/components/Input/TextArea';
import { Mail } from '@/icons/Mail';
import { emailPattern, isValidEmail } from '@/utils/email';
import { generatePassword } from '@/utils/generatePassword';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const FormUsers = memo(({ id, onSubmit, setHasFormChanged, defaultValues = {}, roles, isEdit }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors, isSubmitted, isSubmitting, dirtyFields }, setValue, watch, } = useForm({
        defaultValues: Object.assign(Object.assign({}, defaultValues), { operatorRoleId: (_a = defaultValues.operatorRole) === null || _a === void 0 ? void 0 : _a.id }),
    });
    useEffect(() => {
        if (setHasFormChanged)
            setHasFormChanged(!isSubmitting && !!Object.keys(dirtyFields).length);
    }, [setHasFormChanged, isSubmitting, dirtyFields]);
    const validateTrimValue = (value) => !(value === null || value === void 0 ? void 0 : value.trim()) && !isEdit ? t('FIELD_REQUIRED_ERROR') : true;
    const roleListOptions = roles.map((role) => ({
        value: +role.id,
        label: role.name,
    }));
    const email = watch('email');
    const emailValid = isValidEmail(email);
    useEffect(() => {
        if (!emailValid)
            setValue('isSendToEmail', false);
    }, [emailValid]);
    return (_jsxs("form", Object.assign({ id: id, onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs(FormFieldset, Object.assign({ title: t('Для входа в систему') }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Логин'), labelFor: 'username', controlSize: '250', help: (_b = errors.username) === null || _b === void 0 ? void 0 : _b.message, isRequired: true, hasError: !!errors.username }, { children: _jsx(Controller, { name: 'username', control: control, rules: {
                                maxLength: {
                                    value: 200,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: numberRound(200),
                                        count: 200,
                                    }),
                                },
                                required: {
                                    value: true,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                                pattern: {
                                    value: /^[A-Z0-9@._+-]+$/i,
                                    message: t('только латиница'),
                                },
                                validate: validateTrimValue,
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'username', placeholder: t('введите логин'), hasError: !!errors.username, autoFocus: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Пароль'), labelFor: 'password', controlSize: '250', help: (_c = errors.password) === null || _c === void 0 ? void 0 : _c.message, isRequired: !isEdit, hasError: !!errors.password }, { children: _jsx(Controller, { name: 'password', control: control, rules: {
                                maxLength: {
                                    value: 200,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: numberRound(200),
                                        count: 200,
                                    }),
                                },
                                required: {
                                    value: !isEdit,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                                validate: validateTrimValue,
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'password', placeholder: isEdit ? '********' : t('введите пароль'), rightButton: _jsx(Button, Object.assign({ isOutlined: true, onClick: () => {
                                        setValue('password', generatePassword(), {
                                            shouldValidate: isSubmitted,
                                        });
                                    }, type: 'button' }, { children: t('сгенерировать') })), hasError: !!errors.password }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Email'), labelFor: 'email', controlSize: '250', help: (_d = errors.email) === null || _d === void 0 ? void 0 : _d.message, hasError: !!errors.email }, { children: _jsx(Controller, { name: 'email', control: control, rules: {
                                maxLength: {
                                    value: 200,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: numberRound(200),
                                        count: 200,
                                    }),
                                },
                                pattern: {
                                    value: emailPattern,
                                    message: t('неверный Email'),
                                },
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'email', type: 'email', placeholder: t('name@mail.com'), leftIcon: _jsx(Mail, { className: styles.mailInputIcon }), hasError: !!errors.email }))) }) })), _jsx(FormFieldRow, Object.assign({ controlSize: '360' }, { children: _jsx(Controller, { name: 'isSendToEmail', control: control, render: ({ field }) => (_jsx(Checkbox, Object.assign({}, field, { value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                                    setValue('isSendToEmail', e.target.checked, {
                                        shouldDirty: true,
                                    });
                                }, disabled: !emailValid }, { children: t('отправить данные для входа на почту') }))) }) }))] })), _jsxs(FormFieldset, Object.assign({ title: t('Для отображения в системе') }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Имя'), labelFor: 'name', controlSize: '250', help: (_e = errors.name) === null || _e === void 0 ? void 0 : _e.message, hasError: !!errors.name }, { children: _jsx(Controller, { name: 'name', control: control, rules: {
                                maxLength: {
                                    value: 200,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: numberRound(200),
                                        count: 200,
                                    }),
                                },
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'name', placeholder: t('введите имя пользователя'), hasError: !!errors.name }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Роль (уровень доступа)'), labelFor: 'role', controlSize: '250', help: (_f = errors.operatorRoleId) === null || _f === void 0 ? void 0 : _f.message, isRequired: true, hasError: !!errors.operatorRoleId }, { children: _jsx(Controller, { name: 'operatorRoleId', rules: {
                                required: {
                                    value: true,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                            }, control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'operatorRoleId', placeholder: t('выберите роль'), value: roleListOptions.find((option) => option.value === +field.value) || null, onChange: (role) => {
                                    setValue('operatorRoleId', role === null || role === void 0 ? void 0 : role.value, {
                                        shouldValidate: isSubmitted,
                                        shouldDirty: true,
                                    });
                                }, options: roleListOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasError: !!errors.operatorRoleId, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Доступ открыт до'), labelFor: 'availableTo', controlSize: '250', help: (_g = errors.availableTo) === null || _g === void 0 ? void 0 : _g.message, hasError: !!errors.availableTo }, { children: _jsx(Controller, { name: 'availableTo', control: control, render: ({ field }) => {
                                var _a;
                                return (_jsx(DateInput, { id: 'availableTo', placeholderText: t('дд.мм.гггг'), selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                                        setValue('availableTo', date ? moment(date).format() : '', {
                                            shouldValidate: true,
                                        });
                                    }, hasError: !!((_a = errors.availableTo) === null || _a === void 0 ? void 0 : _a.message) }));
                            } }) })), _jsx(FormFieldRow, Object.assign({ label: t('Комментарий'), labelFor: 'comment', controlSize: '360', help: t('видят только администраторы') }, { children: _jsx(Controller, { name: 'comment', control: control, render: ({ field }) => (_jsx(TextArea, Object.assign({}, field, { id: 'comment', placeholder: t('введите комментарий'), rows: 3 }))) }) }))] }))] })));
});
