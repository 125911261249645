import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/components/Modal/Modal';
import { NotificationOfClosureModalForForm } from '../NotificationOfClosureModalForForm/NotificationOfClosureModalForForm';
export const ClientEditModal = memo(({ isOpen, onClose, activeTab = 'overview', onChangeTab, clientOverview, clientForm, hasFormChanged, }) => {
    const { t } = useTranslation();
    const tabs = [
        {
            id: 'overview',
            label: t('Обзор'),
        },
        {
            id: 'edit',
            label: t('Редактировать профиль'),
        },
    ];
    return (_jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, tabs: tabs, activeTabId: activeTab, onChangeActiveTab: onChangeTab, isForm: activeTab === 'edit', hasNoPadding: activeTab === 'edit', size: '870', isNotificationOfClosureOpen: hasFormChanged, notificationOfClosure: _jsx(NotificationOfClosureModalForForm, {}) }, { children: [activeTab === 'overview' && clientOverview, activeTab === 'edit' && clientForm] })));
});
