var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Modal } from '@/components/Modal/Modal';
import { NotificationOfClosureModalForForm } from '@/components/NotificationOfClosureModalForForm/NotificationOfClosureModalForForm';
import { Cancel } from '@/icons/Cancel';
import { Cart } from '@/icons/header/Cart';
import { FormPurchase } from './FormPurchase';
import { generateTxid } from './generateTxid';
import styles from './styles.module.scss';
import { useDiscardPurchaseTicket } from './useDiscardPurchaseTicket';
export const FormPurchaseModal = memo((_a) => {
    var { id = 'purchase-form', isOpen, onClose, onDelete, selectedPurchase } = _a, props = __rest(_a, ["id", "isOpen", "onClose", "onDelete", "selectedPurchase"]);
    const { t } = useTranslation();
    const [client, setClient] = useState(null);
    const [showItems, setShowItems] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [hasFormChanged, setHasFormChanged] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const [txid, setTxid] = useState('');
    useEffect(() => {
        setTxid(generateTxid());
    }, [isOpen]);
    const [calculation, setCalculation] = useState(undefined);
    const discardPurchaseTicket = useDiscardPurchaseTicket();
    const handleDiscardPurchaseTicket = () => {
        if (calculation === null || calculation === void 0 ? void 0 : calculation.ticket)
            discardPurchaseTicket({
                ticket: calculation.ticket,
                txid,
            });
    };
    const isEdit = !!selectedPurchase;
    const labelWidth = showItems ? 25.35 : 27.28;
    const handleDelete = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!selectedPurchase || !onDelete)
            return;
        setIsSubmitting(true);
        const result = yield onDelete({
            purchase: selectedPurchase,
            txid: selectedPurchase.txid,
        });
        if (result)
            onClose === null || onClose === void 0 ? void 0 : onClose({ deleted: true });
        else
            setIsSubmitting(false);
    }), [selectedPurchase, onDelete, onClose]);
    const footer = (_jsxs(_Fragment, { children: [error && (_jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth, asErrorMessage: true, asModalFooter: true }, { children: error }))), _jsx("div", Object.assign({ className: styles.footer }, { children: isEdit ? (_jsx(_Fragment, { children: userHasAccessTo('CLIENTS_PURCHASES_MANAGE') && (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ form: id, type: 'submit', disabled: isSubmitting, size: 'large' }, { children: t('Сохранить изменения') })), _jsx(Button, Object.assign({ type: 'button', theme: 'danger', icon: _jsx(Cancel, {}), disabled: isSubmitting, onClick: handleDelete }, { children: t('Удалить покупку') }))] })) })) : (_jsx(Button, Object.assign({ form: id, type: 'submit', theme: 'primary', disabled: !client || isSubmitting, size: 'extra-large' }, { children: !client ? t('Выберите клиента') : t('Добавить покупку') }))) }))] }));
    return (_jsx(Modal, Object.assign({ isOpen: isOpen, onClose: () => {
            onClose && onClose();
            handleDiscardPurchaseTicket();
        }, titleIcon: _jsx(Cart, {}), titleClassName: styles.title, title: isEdit ? t('Изменить покупку') : t('Добавить покупку'), footer: footer, isNotificationOfClosureOpen: hasFormChanged, notificationOfClosure: _jsx(NotificationOfClosureModalForForm, {}), size: showItems ? '900' : '840', isForm: true }, { children: _jsx(FormPurchase, Object.assign({ id: id, setHasFormChanged: setHasFormChanged, selectedPurchase: selectedPurchase, onChangeClient: setClient, onToggleItems: setShowItems, onChangeSubmitting: setIsSubmitting, onAdded: () => onClose === null || onClose === void 0 ? void 0 : onClose({ added: true }), onUpdated: () => onClose === null || onClose === void 0 ? void 0 : onClose({ updated: true }), onError: setError }, props, { txid: txid, calculation: calculation, setCalculation: setCalculation, handleDiscardPurchaseTicket: handleDiscardPurchaseTicket })) })));
});
