var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MailingsForm } from '@/app/pages/MailingsForm/MailingsForm';
import { ROUTES } from '@/app/pages/routes';
import { useUserPermissions } from '@/app/useUserPermissions';
import { BetaBadge } from '@/components/BetaBadge/BetaBadge';
import { Button } from '@/components/Button2/Button';
import { Drawer } from '@/components/Drawer/Drawer';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Back } from '@/icons/Back';
import { Plus } from '@/icons/Plus';
import { useDeleteFlow } from '../Flows/useDeleteFlow';
import { RenameFlowForm } from './RenameFlowForm';
import styles from './styles.module.scss';
import { useSetFlow } from './useSetFlow';
export const EditFlowForm = ({ flow }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [renameMode, setRenameMode] = useState(false);
    const [addMode, setAddMode] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const userHasMailingsManage = userHasAccessTo('AUTO_MAILINGS_MANAGE');
    const userHasFlowsManage = userHasAccessTo('FLOWS_MANAGE');
    const goToList = () => {
        navigate(ROUTES.FLOWS);
    };
    const { deleteFlow, isDeletingFlow } = useDeleteFlow(goToList);
    const { updateFlow, isUpdatingFlow } = useSetFlow();
    const handleSubmit = (title) => __awaiter(void 0, void 0, void 0, function* () {
        yield updateFlow(flow.id, title);
        setRenameMode(false);
    });
    if (!renameMode)
        return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames(styles.form, styles.edit) }, { children: [_jsxs("div", Object.assign({ className: styles.titleWrapper }, { children: [_jsx("button", Object.assign({ className: styles.back, type: 'button', onClick: goToList }, { children: _jsx(Back, {}) })), _jsx("div", Object.assign({ className: styles.title }, { children: flow.title })), userHasFlowsManage && (_jsx(Dropdown, { triggerClassName: styles.dropdown, placement: 'bottom-start', items: [
                                        {
                                            id: 'rename',
                                            label: t('Переименовать'),
                                            onClick: () => setRenameMode(true),
                                            isDisabled: isDeletingFlow,
                                        },
                                        {
                                            id: 'delete',
                                            label: t('Распустить'),
                                            onClick: () => deleteFlow(flow),
                                            isDanger: true,
                                            isDisabled: isDeletingFlow,
                                        },
                                    ] })), _jsx(BetaBadge, { tooltip: _jsx("div", Object.assign({ className: styles.tooltip }, { children: t('Скоро здесь можно будет полностью редактировать и создавать цепочки на холсте') })) })] })), _jsx(Tooltip, Object.assign({ title: !userHasMailingsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ icon: _jsx(Plus, {}), theme: 'primary', size: 'large', onClick: () => setAddMode(true), disabled: !userHasMailingsManage }, { children: t('Добавить рассылку') })) }))] })), _jsx(Drawer, Object.assign({ open: addMode, onClose: () => setAddMode(false), anchor: 'left' }, { children: addMode && (_jsx(MailingsForm, { flow: flow, onGoBack: () => setAddMode(false), isStandalone: true })) }))] }));
    return (_jsx(RenameFlowForm, { defaultTitle: flow.title, onSubmit: handleSubmit, onCancel: () => setRenameMode(false), disabled: isUpdatingFlow }));
};
