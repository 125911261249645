import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { FiltersButton } from '@/components/FiltersPanel/FiltersButton';
import { FiltersPanel } from '@/components/FiltersPanel/FiltersPanel';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { FormPurchaseModalContainer } from '@/forms/FormPurchase/FormPurchaseModalContainer';
import { FormPurchaseWithDetailsModal } from '@/forms/FormPurchaseWithDetails/FormPurchaseWithDetailsModal';
import { usePurchaseFilters } from '@/services/filters/data/usePurchaseFilters';
import { ROUTES } from '../routes';
import { PurchasesPanel } from './PurchasesPanel';
import styles from './styles.module.scss';
import { useData } from './useData';
import { useExportXlsx } from './useExportXlsx';
import { useFiltersState } from './useFiltersState';
export const Purchases = () => {
    var _a;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { brand, user, currency } = useCurrentUser();
    const [searchText, setSearchText] = useState('');
    const [purchaseWithDetailsModalIsOpened, setPurchaseWithDetailsModalIsOpened,] = useState(false);
    const [addPurchaseModalIsOpened, setAddPurchaseModalIsOpened] = useState(false);
    const [selectedPurchaseIndex, setSelectedPurchaseIndex] = useState(null);
    const filtersTree = usePurchaseFilters();
    const { filters, compiledFilters, updateFilters, reset } = useFiltersState();
    const { count, data, isLoading, isFetching, limit, offset, setOffset, refetchPurchases, } = useData({
        filters: (filters === null || filters === void 0 ? void 0 : filters.filters.length) ? compiledFilters : undefined,
        searchText,
    });
    const exportXlsx = useExportXlsx({
        searchText: searchText || undefined,
        filters: (filters === null || filters === void 0 ? void 0 : filters.filters.length) ? compiledFilters : {},
        onSuccess: () => navigate(ROUTES.EXPORTS),
    });
    const handleApplySearch = useCallback((searchText) => {
        setOffset(0);
        setSearchText(searchText);
    }, []);
    const handleUpdateFilters = useCallback((filters) => {
        setOffset(0);
        updateFilters(filters);
    }, []);
    const handleResetFilters = useCallback(() => {
        setOffset(0);
        reset();
    }, []);
    const selectedPurchaseData = data === null || data === void 0 ? void 0 : data[selectedPurchaseIndex || 0];
    if (!brand || !user)
        return null;
    return (_jsxs(Container, Object.assign({ notMobileRightPadding: true, topElementClassName: classNames({
            [styles.hasFilters]: (_a = filters === null || filters === void 0 ? void 0 : filters.filters) === null || _a === void 0 ? void 0 : _a.length,
        }), topElement: _jsx(DatePeriodSelectContainer, { label: t('Показать покупки за'), leftElement: _jsxs("div", Object.assign({ className: styles.searchPanel }, { children: [_jsx(FiltersButton, { className: styles.filterButton, filtersTree: filtersTree, value: (filters === null || filters === void 0 ? void 0 : filters.filters) || [], onUpdate: handleUpdateFilters }), _jsx(SearchPanel, { className: styles.search, name: 'searchText', placeholder: t('Номер телефона, ФИО, email или отсканируйте карту'), hasAutoFocus: true, hasMaxWidth: true, onApplySearch: handleApplySearch })] })) }) }, { children: [_jsx(FiltersPanel, { filtersTree: filtersTree, value: (filters === null || filters === void 0 ? void 0 : filters.filters) || [], onUpdate: handleUpdateFilters, hasResetButton: !!(filters === null || filters === void 0 ? void 0 : filters.filters.length), onResetFilters: handleResetFilters }), _jsx(PurchasesPanel, { count: count, purchases: data, currency: currency, isLoading: isLoading, isFetching: isFetching, limit: limit, offset: offset, onChangeOffset: setOffset, onOpenPurchaseAddForm: () => {
                    setAddPurchaseModalIsOpened(true);
                }, onRowClick: (index) => {
                    setSelectedPurchaseIndex(index);
                    setPurchaseWithDetailsModalIsOpened(true);
                }, onExportXlsx: exportXlsx }), _jsx(FormPurchaseWithDetailsModal, { isOpen: purchaseWithDetailsModalIsOpened, onClose: (result) => {
                    setPurchaseWithDetailsModalIsOpened(false);
                    if ((result === null || result === void 0 ? void 0 : result.added) || (result === null || result === void 0 ? void 0 : result.deleted))
                        if (offset !== 0)
                            setOffset(0);
                        else
                            refetchPurchases();
                }, currency: currency, selectedPurchase: selectedPurchaseData, setSelectedPurchaseIndex: setSelectedPurchaseIndex, selectedPurchaseIndex: selectedPurchaseIndex, purchasesListLength: (data === null || data === void 0 ? void 0 : data.length) || 0 }), _jsx(FormPurchaseModalContainer, { isOpen: addPurchaseModalIsOpened, onClose: (result) => {
                    setAddPurchaseModalIsOpened(false);
                    if ((result === null || result === void 0 ? void 0 : result.added) || (result === null || result === void 0 ? void 0 : result.deleted))
                        if (offset !== 0)
                            setOffset(0);
                        else
                            refetchPurchases();
                }, currency: currency })] })));
};
