var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FileUploadInput, } from '@/components/FileUploadInput/FileUploadInput';
import { ImageCrop, } from '@/components/ImageCrop/ImageCrop';
import { Modal } from '@/components/Modal/Modal';
import { getPreviewSize } from './getPreviewSize';
import { readImage } from './readImage';
import styles from './styles.module.scss';
export const ImageCropInput = ({ id, className, value, onChange, onReject, previewWidth, previewHeight, previewAsIcon, defaultPreviewValue, maintainPreviewAspectRatio, placeholder, description, constraints, hasError, onFocus, cropModalIcon, cropModalTitle, cropModalFooter, isRound, theme = 'solid', }) => {
    const { t } = useTranslation();
    const [preview, setPreview] = useState(value instanceof File ? null : (value === null || value === void 0 ? void 0 : value.url) || null);
    const [previewSize, setPreviewSize] = useState({
        width: previewWidth,
        height: previewHeight,
    });
    const [cropImageUrl, setCropImageUrl] = useState(null);
    const [crop, setCrop] = useState(null);
    const { dimensions, shape } = constraints || {};
    useEffect(() => {
        const instance = !value && defaultPreviewValue ? defaultPreviewValue : value;
        if (instance && instance instanceof File) {
            readImage(instance).then(({ url, width, height }) => {
                setPreview(url);
                setPreviewSize(getPreviewSize({
                    previewWidth,
                    previewHeight,
                    imageWidth: width,
                    imageHeight: height,
                    maintainPreviewAspectRatio,
                }));
            });
            return;
        }
        if (instance) {
            setPreview(instance.url);
            if (maintainPreviewAspectRatio)
                setPreviewSize(getPreviewSize({
                    previewWidth,
                    previewHeight,
                    imageWidth: instance.width,
                    imageHeight: instance.height,
                    maintainPreviewAspectRatio,
                }));
            return;
        }
        if (!instance && !defaultPreviewValue) {
            setPreview(null);
            setPreviewSize({ width: previewWidth, height: previewHeight });
        }
    }, [value, defaultPreviewValue]);
    const handleChangeFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
        if (!file) {
            onChange(null);
            setPreview('');
            setPreviewSize({ width: previewWidth, height: previewHeight });
            return;
        }
        const { width, height, url } = yield readImage(file);
        if (dimensions) {
            const minHeight = dimensions.height;
            const [minWidth] = Array.isArray(dimensions.width)
                ? dimensions.width
                : [dimensions.width];
            if (width < minWidth || height < minHeight) {
                setPreview('');
                setPreviewSize({ width: previewWidth, height: previewHeight });
                onReject('file-invalid-dimensions');
                return;
            }
            if (width > minWidth || height > minHeight) {
                setCropImageUrl(url);
                return;
            }
        }
        else if (shape) {
            setCropImageUrl(url);
            return;
        }
        onChange(file, width, height);
    });
    const handleRejectFile = (reason) => {
        if (reason === 'too-many-files')
            return;
        setPreview('');
        setPreviewSize({ width: previewWidth, height: previewHeight });
        if (reason === 'file-too-large') {
            onReject('file-too-large');
            return;
        }
        if (reason === 'file-invalid-type') {
            onReject('file-invalid-type');
            return;
        }
        onReject();
    };
    const handleApplyCrop = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!crop)
            return;
        try {
            const file = yield crop.getPreviewFile();
            const { width, height } = crop.getPreviewSize();
            onChange(file, width, height);
        }
        catch (e) {
            setPreview('');
            setPreviewSize({ width: previewWidth, height: previewHeight });
            onReject();
        }
        setCropImageUrl(null);
    });
    return (_jsxs(_Fragment, { children: [_jsx(FileUploadInput, { id: id, className: className, placeholder: placeholder !== 'image' ? t('Поместите картинку') : undefined, placeholderIcon: placeholder === 'image' ? 'image' : undefined, description: description, width: previewSize.width, height: previewSize.height, filename: value === null || value === void 0 ? void 0 : value.name, previewImageUrl: preview ? `url(${preview})` : undefined, previewAsIcon: previewAsIcon, previewHasOpacity: !value && !!defaultPreviewValue, constraints: constraints, hasError: hasError, onChange: handleChangeFile, onReject: handleRejectFile, theme: theme, onFocus: onFocus }), (!!dimensions || !!shape) && !!cropImageUrl && (_jsx(Modal, Object.assign({ titleIcon: cropModalIcon, title: cropModalTitle, footer: _jsxs("div", Object.assign({ className: styles.footer }, { children: [cropModalFooter, _jsx(Button, Object.assign({ type: 'button', size: 'large', theme: 'dark', onClick: handleApplyCrop }, { children: t('Применить') }))] })), isOpen: true, onClose: () => setCropImageUrl(null), hasNoPadding: true, hasNoOverflow: true, size: '600', theme: 'dark' }, { children: _jsx(ImageCrop, { src: cropImageUrl, cropWidth: dimensions === null || dimensions === void 0 ? void 0 : dimensions.width, cropHeight: dimensions === null || dimensions === void 0 ? void 0 : dimensions.height, cropShape: shape, onCrop: setCrop, isRound: isRound }) })))] }));
};
