import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { rejectUploadReason } from '@/components/FileUploadInput/rejectUploadReason';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { ImageCropInput } from '@/components/ImageCropInput/ImageCropInput';
import styles from '../styles.module.scss';
export const FormMailingPushImage = ({ uploadImage, labelWidth, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, setValue, setError, formState } = useFormContext();
    const { errors } = formState;
    return (_jsx(FormFieldRow, Object.assign({ className: styles.imageRow, label: t('Картинка на Wallet-карте'), labelFor: 'walletImage', labelWidth: labelWidth, help: (_a = errors.walletImage) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.walletImage }, { children: _jsx(Controller, { name: 'walletImage', control: control, render: ({ field }) => (_jsx(ImageCropInput, { id: 'walletImage', value: field.value || null, onChange: (file) => {
                    setValue('walletImage', file, {
                        shouldValidate: true,
                        shouldDirty: true,
                    });
                    if (file && uploadImage)
                        uploadImage({ file }).then((payload) => {
                            setValue('walletImageData', payload);
                        });
                }, onReject: (reason) => {
                    const message = rejectUploadReason(reason, '', '1125×433', true);
                    if (message)
                        setError('walletImage', { message: t(message) });
                    setValue('walletImage', null);
                }, previewWidth: 300, previewHeight: 115, description: t('от') + ' ' + t('1125×433, до 10мб'), constraints: {
                    mimeType: 'image/*',
                    maxSize: 10000000,
                    dimensions: { width: 1125, height: 433 },
                }, hasError: !!errors.walletImage, cropModalTitle: t('Картинка на Wallet-карте'), theme: 'outlined' })) }) })));
};
