import { $getSelection, $insertNodes, $isRangeSelection, createCommand, } from 'lexical';
import { $createVariableNode } from './VariableNode';
export const INSERT_VARIABLE = createCommand();
export function insertVariable(variable, editor) {
    editor.update(() => {
        const node = $createVariableNode(variable);
        $insertNodes([node]);
    });
    return true;
}
export function insertVariableText(variable) {
    const selection = $getSelection();
    if (selection && $isRangeSelection(selection)) {
        selection.insertText(`!${variable.id}`);
        return true;
    }
    return false;
}
