var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const getImageDimension = (dataURI) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => {
            resolve([image.width, image.height]);
        });
        image.addEventListener('error', reject);
        image.addEventListener('abort', reject);
        image.src = dataURI;
    });
};
export const readImage = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('error', reject);
        reader.addEventListener('abort', reject);
        reader.addEventListener('load', () => __awaiter(void 0, void 0, void 0, function* () {
            const url = reader.result;
            try {
                const [width, height] = yield getImageDimension(url);
                resolve({ width, height, url });
            }
            catch (_a) {
                reject();
            }
        }), false);
        reader.readAsDataURL(file);
    });
};
