import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position } from '@xyflow/react';
import classNames from 'classnames';
import { Clock } from '@/icons/flow/Clock';
import styles from './styles.module.scss';
import { useDelayContent } from './useDelayContent';
export const DelayNode = ({ data, selected, }) => {
    const { offsetSign = 0, offsetUnit = 'day', offsetValue = 1 } = data;
    const delayContent = useDelayContent(offsetSign, offsetUnit, offsetValue);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames(styles.wrapper, {
                    [styles.selected]: selected,
                }) }, { children: [_jsx(Clock, {}), _jsx("span", Object.assign({ className: styles.content }, { children: delayContent }))] })), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target', 'flow-handle-target-delay'), type: 'target', position: Position.Left }), _jsx(Handle, { className: 'flow-handle', type: 'source', position: Position.Right })] }));
};
