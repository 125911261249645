import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { TRIGGER_TYPE_BIRTHDAY, TRIGGER_TYPE_BONUS_EXPIRING, TRIGGER_TYPE_CHILD_BIRTHDAY, } from '@/components/MailingTriggerTypeInput/useOptions';
import styles from '../styles.module.scss';
export const OFFSET_SIGN_AT = '0';
export const OFFSET_SIGN_AFTER = '1';
export const OFFSET_SIGN_BEFORE = '-1';
export const OFFSET_SIGN_SCHEDULE = '2';
export const FormMailingOffset = ({ labelWidth, isDisabled, isDisabledAfterOption, isDisabledBeforeOption, isDisabledScheduleOption, }) => {
    const { t, i18n } = useTranslation();
    const { control, formState, setValue, watch } = useFormContext();
    const { errors } = formState;
    const offsetValue = watch('offsetValue') || 0;
    const offsetSign = watch('offsetSign');
    const triggerType = watch('triggerType');
    const mailingHasOffset = offsetSign !== OFFSET_SIGN_AT && offsetSign !== OFFSET_SIGN_SCHEDULE;
    const offsetSignOptions = [
        { value: OFFSET_SIGN_AT, label: t('сразу') },
        { value: OFFSET_SIGN_AFTER, label: t('через') },
        { value: OFFSET_SIGN_BEFORE, label: t('за') },
        { value: OFFSET_SIGN_SCHEDULE, label: t('по расписанию') },
    ];
    const offsetUnitOptions = [
        { value: 'minute', label: t('OFFSET_VALUE_MIN', { offset: offsetValue }) },
        { value: 'hour', label: t('OFFSET_VALUE_HOUR', { offset: offsetValue }) },
        { value: 'day', label: t('OFFSET_VALUE_DAY', { offset: offsetValue }) },
        { value: 'month', label: t('OFFSET_VALUE_MONTH', { offset: offsetValue }) },
    ];
    const handleChangeOffsetSign = (offsetSign) => {
        setValue('offsetSign', offsetSign === null || offsetSign === void 0 ? void 0 : offsetSign.value);
        if ((offsetSign === null || offsetSign === void 0 ? void 0 : offsetSign.value) === OFFSET_SIGN_BEFORE &&
            triggerType !== TRIGGER_TYPE_BIRTHDAY &&
            triggerType !== TRIGGER_TYPE_CHILD_BIRTHDAY &&
            triggerType !== TRIGGER_TYPE_BONUS_EXPIRING)
            setValue('triggerType', TRIGGER_TYPE_BIRTHDAY);
    };
    return (_jsx(FormFieldRow, Object.assign({ label: t('Отправлять'), labelFor: 'offsetSign', labelWidth: labelWidth, isRequired: mailingHasOffset }, { children: _jsxs("div", Object.assign({ className: styles.offsetRow }, { children: [_jsx(Controller, { name: 'offsetSign', control: control, render: ({ field }) => (_jsx("div", Object.assign({ className: classNames(styles.offsetSignInput, styles[i18n.language], { [styles.large]: offsetSign === OFFSET_SIGN_SCHEDULE }) }, { children: _jsx(Autocomplete, Object.assign({}, field, { id: 'offsetSign', value: offsetSignOptions.find((item) => item.value === field.value) || null, onChange: handleChangeOffsetSign, options: offsetSignOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, minListWidth: 141, asSelect: true, disabled: isDisabled, isOptionDisabled: (option) => {
                                if (option.value === OFFSET_SIGN_AFTER &&
                                    isDisabledAfterOption)
                                    return true;
                                if (option.value === OFFSET_SIGN_BEFORE &&
                                    isDisabledBeforeOption)
                                    return true;
                                if (option.value === OFFSET_SIGN_SCHEDULE &&
                                    isDisabledScheduleOption)
                                    return true;
                                return false;
                            } })) }))) }), mailingHasOffset && (_jsx(Controller, { name: 'offsetValue', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                        min: {
                            value: 1,
                            message: t('MIN_ERROR_MESSAGE', {
                                amount: 1,
                            }),
                        },
                    }, render: ({ field }) => (_jsx("div", Object.assign({ className: styles.offsetValueInput }, { children: _jsx(Input, Object.assign({}, field, { id: 'offsetValue', type: 'number', inputMode: 'numeric', step: 'any', hasError: !!errors.offsetValue, preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true, disabled: isDisabled })) }))) })), mailingHasOffset && (_jsx(Controller, { name: 'offsetUnit', control: control, render: ({ field }) => (_jsx("div", Object.assign({ className: styles.offsetUnitInput }, { children: _createElement(Autocomplete, Object.assign({}, field, { key: offsetValue, id: 'offsetUnit', value: offsetUnitOptions.find((item) => item.value === field.value) || null, onChange: (offsetUnit) => {
                                setValue('offsetUnit', offsetUnit === null || offsetUnit === void 0 ? void 0 : offsetUnit.value);
                            }, options: offsetUnitOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true, disabled: isDisabled })) }))) }))] })) })));
};
