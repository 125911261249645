var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useUpdateShopMutation } from '@/api/query/shop';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useUpdateShop = () => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [updateShop] = useUpdateShopMutation();
    const handleUpdateShop = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (!payload.id)
            return '';
        const result = yield updateShop(Object.assign(Object.assign({}, payload), { id: payload.id, timezoneId: (_a = payload.timezone) === null || _a === void 0 ? void 0 : _a.id, productCatalogId: (_b = payload.productCatalog) === null || _b === void 0 ? void 0 : _b.id }));
        const error = getErrorMessage(result);
        if (error)
            return error;
        else {
            addToast({ type: 'success', message: t('Точка продаж сохранена') });
            return '';
        }
    });
    return handleUpdateShop;
};
