import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { ImageCropInput } from '@/components/ImageCropInput/ImageCropInput';
import { EmojiButton } from '@/components/rich-text/EmojiButton/EmojiButton';
import { useAppSelector } from '@/hooks/store';
import { publishButtonClickedSelector } from '@/store/selectors/wallet';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { ImageHelp } from '../../helpers/ImageHelp';
import styles from '../../styles.module.scss';
export const BannerCounter = () => {
    const { t } = useTranslation();
    const { markReadyToSave, uploadImage, bannerType, bannerCounter, setBannerCounter, counters = [], } = useWalletCardForm();
    const shouldDisplayErrors = useAppSelector(publishButtonClickedSelector);
    const hasCounterError = shouldDisplayErrors &&
        bannerType === 'with-counter' &&
        !bannerCounter.offerId;
    const handleChange = (value) => {
        setBannerCounter((bannerCounter) => (Object.assign(Object.assign({}, bannerCounter), value)));
    };
    const handleApplyImage = (imageType) => (image) => {
        handleChange({
            [imageType]: image,
            [imageType + 'UploadResponse']: undefined,
            [imageType + 'Loading']: !!image,
            [imageType + 'RejectReason']: '',
        });
        if (!image) {
            markReadyToSave();
            return;
        }
        uploadImage === null || uploadImage === void 0 ? void 0 : uploadImage(image).then((response) => {
            handleChange({ [imageType + 'UploadResponse']: response });
        }).finally(() => {
            handleChange({ [imageType + 'Loading']: false });
            markReadyToSave();
        });
    };
    const handleApplyEmoji = (imageType) => (emoji, unified) => {
        const emojiId = unified.replace(/-/g, '_');
        const emojiName = `emoji_u${emojiId}.png`;
        const emojiUrl = `http://s.maxma.com/emoji/160/${emojiName}`;
        const emojiImage = {
            id: emojiId,
            name: emojiName,
            url: emojiUrl,
            mimeType: 'image/png',
            width: 160,
            height: 160,
        };
        handleChange({
            [imageType]: emojiImage,
            [imageType + 'UploadResponse']: emojiImage,
            [imageType + 'Loading']: false,
            [imageType + 'RejectReason']: '',
        });
        markReadyToSave();
    };
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles.counterTitle }, { children: t('Счетчик') })), _jsxs("div", Object.assign({ className: styles.counterSelect }, { children: [_jsx(Autocomplete, { id: 'banner-counter', options: counters, value: counters.find((counter) => counter.offerId === bannerCounter.offerId) || null, onChange: (counter) => {
                            handleChange({ offerId: counter === null || counter === void 0 ? void 0 : counter.offerId });
                            markReadyToSave();
                        }, getOptionKey: (option) => option.offerId, getOptionLabel: (option) => option.counterName, placeholder: t('Выберите счетчик'), hasResetButton: false, asSelect: true, hasError: hasCounterError }), _jsx("div", Object.assign({ className: styles.description }, { children: t('Баннер выведет не более 60-ти значков') }))] })), _jsxs("div", Object.assign({ className: styles.counterImages }, { children: [_jsxs("div", Object.assign({ className: classNames(styles.control, styles.smallGap) }, { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'banner-counter-tick-image' }, { children: t('Накоплено') })), _jsxs("div", Object.assign({ className: styles.uploadWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.counterImage }, { children: [_jsx(ImageCropInput, { id: 'banner-counter-tick-image', value: bannerCounter.tickImage, onChange: handleApplyImage('tickImage'), onReject: (reason) => {
                                                    handleChange({
                                                        tickImage: null,
                                                        tickImageRejectReason: reason || '',
                                                    });
                                                }, previewWidth: 70, previewHeight: 70, previewAsIcon: true, placeholder: 'image', constraints: {
                                                    mimeType: 'image/*',
                                                    extensions: ['.png'],
                                                    maxSize: 10000000,
                                                    dimensions: { width: 200, height: 200 },
                                                }, hasError: !!bannerCounter.tickImageRejectReason, cropModalTitle: t('Накоплено') }), _jsx(EmojiButton, { set: 'apple', onSelectEmoji: handleApplyEmoji('tickImage') })] })), _jsx(ImageHelp, { dimensions: '200\u00D7200', help: '200\u00D7200', rejectReason: bannerCounter.tickImageRejectReason, isLoading: bannerCounter.tickImageLoading, isFloating: true, isShort: true })] }))] })), _jsxs("div", Object.assign({ className: classNames(styles.control, styles.smallGap) }, { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'banner-counter-blank-image' }, { children: t('Осталось накопить') })), _jsxs("div", Object.assign({ className: styles.uploadWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.counterImage }, { children: [_jsx(ImageCropInput, { id: 'banner-counter-blank-image', value: bannerCounter.blankImage, defaultPreviewValue: bannerCounter.tickImage, onChange: handleApplyImage('blankImage'), onReject: (reason) => {
                                                    handleChange({
                                                        blankImage: null,
                                                        blankImageRejectReason: reason || '',
                                                    });
                                                }, previewWidth: 70, previewHeight: 70, previewAsIcon: true, placeholder: 'image', constraints: {
                                                    mimeType: 'image/*',
                                                    extensions: ['.png'],
                                                    maxSize: 10000000,
                                                    dimensions: { width: 200, height: 200 },
                                                }, hasError: !!bannerCounter.blankImageRejectReason, cropModalTitle: t('Осталось накопить') }), _jsx(EmojiButton, { set: 'apple', onSelectEmoji: handleApplyEmoji('blankImage') })] })), _jsx(ImageHelp, { dimensions: '200\u00D7200', help: '200\u00D7200', rejectReason: bannerCounter.blankImageRejectReason, isLoading: bannerCounter.blankImageLoading, isFloating: true, isShort: true })] }))] }))] }))] }));
};
