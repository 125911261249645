var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useAddOperatorMutation, useUpdateOperatorMutation, } from '@/api/query/operator';
import { useToastContext } from '@/components/Toast/ToastContext';
import { FormUsersModal } from './FormUsersModal';
export const FormUsersContainer = ({ selectedUser, onSubmit, roles, isOpen, onClose }) => {
    const [formError, setFormError] = useState('');
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [addOperator, addOperatorResult] = useAddOperatorMutation();
    const [updateOperator, updateOperatorResult] = useUpdateOperatorMutation();
    const isLoading = addOperatorResult.isLoading || updateOperatorResult.isLoading;
    const handleSubmitUser = useCallback((payload) => __awaiter(void 0, void 0, void 0, function* () {
        let result;
        const data = Object.assign(Object.assign({}, payload), { availableTo: payload.availableTo
                ? moment(payload.availableTo).format('DD.MM.YYYY')
                : undefined });
        if (data.id) {
            result = yield updateOperator(data);
            const error = getErrorMessage(result);
            if (error) {
                setFormError(getErrorMessage(error));
                return;
            }
        }
        else {
            result = yield addOperator(data);
            const error = getErrorMessage(result);
            if (error) {
                setFormError(getErrorMessage(error));
                return;
            }
        }
        if ('data' in result) {
            onSubmit(data);
            addToast({ message: t('Пользователь сохранен'), type: 'success' });
        }
        else
            setFormError('');
    }), [updateOperator, addOperator, addToast, onSubmit]);
    return (_jsx(FormUsersModal, { isOpen: isOpen, onClose: onClose, isLoading: isLoading, roles: roles, defaultValues: selectedUser, error: formError, onSubmit: handleSubmitUser, isEdit: !!(selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.id) }));
};
