import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getCashierOptionsSelector, getCategoryOptionsSelector, getClientLevelOptionsSelector, getExtraFieldOptionsSelector, getProductPaginatedOptionsSelector, getProductsOptionsSelector, getRFMSegmentOptionsSelector, getSegmentOptionsSelector, getShopOptionsSelector, } from '@/store/selectors/filters';
import { getBrandExtraFieldOptionsThunk, getCashierOptionsThunk, getCategoryOptionsThunk, getClientLevelOptionsThunk, getProductOptionsThunk, getProductsBranchThunk, getProductsTreeThunk, getRFMSegmentOptionsThunk, getSegmentOptionsThunk, getShopOptionsThunk, } from '@/store/thunk/filters';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
import { buildCounters } from '../buildCounters';
import { buildExtraFields } from '../buildExtraFields';
export const useClientFilters = (extraFields = [], counters = [], customItems = []) => {
    var _a, _b;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { brand, currency } = useCurrentUser();
    const isRFMEnabled = brandHasRFMFeature(brand === null || brand === void 0 ? void 0 : brand.brand);
    const shops = useAppSelector(getShopOptionsSelector);
    const clientLevels = useAppSelector(getClientLevelOptionsSelector);
    const cashiers = useAppSelector(getCashierOptionsSelector);
    const products = useAppSelector(getProductsOptionsSelector);
    const categories = useAppSelector(getCategoryOptionsSelector);
    const paginatedProducts = useAppSelector(getProductPaginatedOptionsSelector);
    const segments = useAppSelector(getSegmentOptionsSelector);
    const rfmSegments = useAppSelector(getRFMSegmentOptionsSelector);
    const extraFieldOptions = useAppSelector(getExtraFieldOptionsSelector);
    const loadExtraFieldOptions = (fieldName) => dispatch(getBrandExtraFieldOptionsThunk({ fieldName }));
    const defaultItems = [
        {
            type: 'branch',
            id: 'segments',
            label: t('Сегменты'),
            items: [
                {
                    type: 'leaf',
                    id: 'clientSegments',
                    label: t('REGULAR_SEGMENTS'),
                    filterTitle: t('Сегменты'),
                    template: 'checkboxes-segments',
                    loadOptions: () => dispatch(getSegmentOptionsThunk()),
                    options: segments,
                    useBothOperations: true,
                },
                {
                    type: 'leaf',
                    id: 'clientRfmSegments',
                    label: 'RFM',
                    filterTitle: t('RFM сегменты'),
                    template: 'checkboxes-segments',
                    loadOptions: () => dispatch(getRFMSegmentOptionsThunk()),
                    options: rfmSegments,
                    useBothOperations: true,
                    locked: !isRFMEnabled,
                },
            ],
        },
        {
            type: 'branch',
            id: 'clients',
            label: t('Клиенты'),
            items: [
                {
                    type: 'leaf',
                    id: 'clientName',
                    label: t('Имя'),
                    template: 'textField',
                },
                {
                    type: 'leaf',
                    id: 'clientSurname',
                    label: t('Фамилия'),
                    template: 'textField',
                },
                {
                    type: 'leaf',
                    id: 'clientPatronymic',
                    label: t('Отчество'),
                    template: 'textField',
                },
                {
                    type: 'leaf',
                    id: 'clientGender',
                    label: t('Пол'),
                    template: 'checkboxes-simple',
                    options: {
                        0: t('Не задан'),
                        1: t('Мужской'),
                        2: t('Женский'),
                    },
                },
                {
                    type: 'leaf',
                    id: 'clientAge',
                    label: t('Возраст'),
                    template: 'numRange',
                    constraints: {
                        minValue: 0,
                        maxValue: 120,
                    },
                    inputPostfix: t('лет'),
                },
                {
                    type: 'leaf',
                    id: 'clientBirthdate',
                    label: t('День рождения'),
                    template: 'date',
                },
                {
                    type: 'leaf',
                    id: 'clientBirthdateDays',
                    label: t('Скоро день рождения'),
                    template: 'birthdateDays',
                },
                {
                    type: 'branch',
                    id: 'client-children',
                    label: t('Дети'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'clientChildName',
                            label: t('Имя ребёнка'),
                            template: 'textField',
                        },
                        {
                            type: 'leaf',
                            id: 'clientChildAge',
                            label: t('Возраст ребёнка'),
                            template: 'numRange',
                            constraints: {
                                minValue: 0,
                                maxValue: 120,
                            },
                            inputPostfix: t('лет'),
                        },
                        {
                            type: 'leaf',
                            id: 'clientChildBirthdate',
                            label: t('День рождения ребёнка'),
                            template: 'date',
                        },
                        {
                            type: 'leaf',
                            id: 'clientChildGender',
                            label: t('Пол ребёнка'),
                            template: 'checkboxes-simple',
                            options: {
                                0: t('Не задан'),
                                1: t('Мужской'),
                                2: t('Женский'),
                            },
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'extraFields',
                    label: t('Дополнительные поля'),
                    items: buildExtraFields(extraFields, extraFieldOptions, loadExtraFieldOptions),
                },
            ],
        },
        {
            type: 'branch',
            id: 'communication-channels',
            label: t('Каналы коммуникации'),
            items: [
                {
                    type: 'leaf',
                    id: 'mailingSubscribed',
                    label: t('Подписки'),
                    template: 'checkboxes-simple',
                    options: {
                        email: t('Email'),
                        phone: t('SMS и Viber'),
                    },
                },
                {
                    type: 'branch',
                    id: 'phone',
                    label: t('Телефон'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'clientPhonePresent',
                            label: t('Телефон указан'),
                            template: 'radios',
                            options: {
                                1: t('Указан'),
                                0: t('Не указан'),
                            },
                        },
                        {
                            type: 'leaf',
                            id: 'clientPhone',
                            label: t('Поиск по телефонам'),
                            template: 'textField',
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'email',
                    label: t('Email'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'clientEmailPresent',
                            label: t('Email указан'),
                            template: 'radios',
                            options: {
                                1: t('Указан'),
                                0: t('Не указан'),
                            },
                        },
                        {
                            type: 'leaf',
                            id: 'clientEmail',
                            label: t('Поиск по email'),
                            template: 'textField',
                        },
                        {
                            type: 'leaf',
                            id: 'clientIsEmailConfirmed',
                            label: t('Подтвердил подписку'),
                            template: 'radios',
                            options: {
                                1: t('Подтвердил'),
                                0: t('Не подтвердил'),
                            },
                        },
                    ],
                },
                {
                    type: 'leaf',
                    id: 'incorrectContacts',
                    label: t('Ошибка в контактах'),
                    template: 'checkboxes-simple',
                    options: {
                        phone: t('Ошибка в телефоне'),
                        email: t('Ошибка в email'),
                    },
                },
            ],
        },
        {
            type: 'branch',
            id: 'loyalty-program',
            label: t('Программа лояльности'),
            items: [
                {
                    type: 'leaf',
                    id: 'clientStatus',
                    label: t('Статус участия'),
                    template: 'checkboxes-simple',
                    options: {
                        3: t('Заблокирован'),
                        2: t('Подписчик'),
                        1: t('Участник программы'),
                    },
                },
                {
                    type: 'branch',
                    id: 'joining-the-program',
                    label: t('Вступление в программу'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'loyaltyCreatedAt',
                            label: t('Дата вступления'),
                            template: 'dateRange',
                        },
                        {
                            type: 'leaf',
                            id: 'loyaltyJoinedDaysAgo',
                            label: t('Дней в программе'),
                            template: 'numRange',
                            inputPostfix: t('дней'),
                        },
                        {
                            type: 'leaf',
                            id: 'clientIssuerShop',
                            label: t('Место вступления'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getShopOptionsThunk()),
                            options: shops,
                            hasUndefinedOption: true,
                            undefinedOptionValue: '0',
                        },
                        {
                            type: 'leaf',
                            id: 'clientIssuerCashier',
                            label: t('Кто добавил'),
                            template: 'checkboxes-paginated-loader',
                            loadOptions: (config) => dispatch(getCashierOptionsThunk(config)),
                            options: cashiers,
                        },
                    ],
                },
                {
                    type: 'leaf',
                    id: 'loyaltyCard',
                    label: t('Поиск по номерам карт'),
                    template: 'textField',
                },
                {
                    type: 'leaf',
                    id: 'clientHasWallet',
                    label: t('Карты Wallet'),
                    template: 'checkboxes-simple',
                    options: {
                        apple: t('Apple Wallet'),
                        android: t('Google Pay'),
                    },
                },
                {
                    type: 'leaf',
                    id: 'clientLevel',
                    label: t('Уровень'),
                    template: 'checkboxes-loader',
                    loadOptions: () => dispatch(getClientLevelOptionsThunk()),
                    options: clientLevels,
                },
                {
                    type: 'branch',
                    id: 'bonuses',
                    label: t('Бонусы'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'loyaltyBonuses',
                            label: t('Бонусов на счету'),
                            template: 'numRange',
                        },
                        {
                            type: 'leaf',
                            id: 'loyaltyAppliedBonuses',
                            label: t('Бонусов использовано'),
                            template: 'numRange',
                        },
                        {
                            type: 'leaf',
                            id: 'loyaltyExpiredBonuses',
                            label: t('Бонусов сгорело'),
                            template: 'numRange',
                        },
                        {
                            type: 'leaf',
                            id: 'loyaltyExpireAt',
                            label: t('Дата сгорания бонусов'),
                            template: 'dateRange',
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'counters',
                    label: t('Счетчики'),
                    items: buildCounters(counters),
                },
            ],
        },
        {
            type: 'branch',
            id: 'purchases',
            label: t('Покупки'),
            items: [
                {
                    type: 'leaf',
                    id: 'purchaseCount',
                    label: t('Количество покупок'),
                    template: 'numRange',
                },
                {
                    type: 'leaf',
                    id: 'clientPurchasesCount',
                    label: t('Всего покупок (для RFM)'),
                    template: 'numRange',
                    hidden: true,
                },
                {
                    type: 'leaf',
                    id: 'purchaseItemCount',
                    label: t('Количество позиций'),
                    template: 'numRange',
                },
                {
                    type: 'leaf',
                    id: 'purchaseTotalAmount',
                    label: t('Сумма покупок'),
                    template: 'numRange',
                    inputPostfix: currency,
                    composePostfixToText: true,
                },
                {
                    type: 'leaf',
                    id: 'purchaseAvg',
                    label: t('Средний чек'),
                    template: 'numRange',
                    inputPostfix: currency,
                    composePostfixToText: true,
                },
                {
                    type: 'leaf',
                    id: 'purchaseMax',
                    label: t('Максимальный чек'),
                    template: 'numRange',
                    inputPostfix: currency,
                    composePostfixToText: true,
                },
                {
                    type: 'leaf',
                    id: 'purchaseAvgDaysBetween',
                    label: t('Дней между покупками'),
                    template: 'numRange',
                    inputPostfix: t('дней'),
                },
                {
                    type: 'branch',
                    id: 'date-of-purchase',
                    label: t('Дата совершения покупок'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'purchaseExecutedAt',
                            label: t('Дата покупки'),
                            template: 'dateRange',
                        },
                        {
                            type: 'leaf',
                            id: 'purchaseExecutedDaysAgo',
                            label: t('Покупка дней назад'),
                            template: 'numRange',
                            inputPostfix: t('дней'),
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'days-without-purchases',
                    label: t('Дней без покупок'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'purchaseLastExecutedAt',
                            label: t('Дата п/покупки'),
                            template: 'dateRange',
                        },
                        {
                            type: 'leaf',
                            id: 'clientLastPurchaseDaysAgo',
                            label: t('Дней без покупок'),
                            template: 'numRange',
                            inputPostfix: t('дней'),
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'place-of-purchase',
                    label: t('Где покупал ранее'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'clientFavoriteShop',
                            label: t('Самое частое место покупки'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getShopOptionsThunk()),
                            options: shops,
                        },
                        {
                            type: 'leaf',
                            id: 'clientLastShop',
                            label: t('Последнее место покупки'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getShopOptionsThunk()),
                            options: shops,
                        },
                        {
                            type: 'leaf',
                            id: 'purchaseShop',
                            label: t('Где покупал хотя бы раз'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getShopOptionsThunk()),
                            options: shops,
                        },
                        {
                            type: 'leaf',
                            id: 'purchaseCashier',
                            label: t('Продавец'),
                            template: 'checkboxes-paginated-loader',
                            loadOptions: (config) => dispatch(getCashierOptionsThunk(config)),
                            options: cashiers,
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'purchase-what',
                    label: t('Что покупал ранее'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'purchaseItem',
                            label: t('Товар'),
                            template: 'checkboxes-tree-loader',
                            loadTree: (config) => dispatch(getProductsTreeThunk(config)),
                            loadBranch: (config) => dispatch(getProductsBranchThunk(config)),
                            options: products,
                        },
                        {
                            type: 'leaf',
                            id: 'purchaseItemSku',
                            label: t('Артикул'),
                            template: 'textField',
                        },
                    ],
                },
            ],
        },
        {
            type: 'branch',
            id: 'returns',
            label: t('Возвраты'),
            items: [
                {
                    type: 'leaf',
                    id: 'returnCount',
                    label: t('Количество возвратов'),
                    template: 'numRange',
                },
                {
                    type: 'leaf',
                    id: 'returnTotalAmount',
                    label: t('Сумма возвратов'),
                    template: 'numRange',
                    inputPostfix: currency,
                    composePostfixToText: true,
                },
                {
                    type: 'branch',
                    id: 'date-of-return',
                    label: t('Дата возврата'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'returnExecutedAt',
                            label: t('Дата возврата'),
                            template: 'dateRange',
                        },
                        {
                            type: 'leaf',
                            id: 'returnExecutedDaysAgo',
                            label: t('Возврат дней назад'),
                            template: 'numRange',
                            inputPostfix: t('дней'),
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'days-without-returns',
                    label: t('Дней без возвратов'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'returnLastExecutedAt',
                            label: t('Дата п/возврата'),
                            template: 'dateRange',
                        },
                        {
                            type: 'leaf',
                            id: 'clientLastReturnDaysAgo',
                            label: t('Дней без возвратов'),
                            template: 'numRange',
                            inputPostfix: t('дней'),
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'place-of-return',
                    label: t('Где возвращал'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'returnShop',
                            label: t('Место возврата'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getShopOptionsThunk()),
                            options: shops,
                        },
                        {
                            type: 'leaf',
                            id: 'returnCashier',
                            label: t('Кто принял'),
                            template: 'checkboxes-paginated-loader',
                            loadOptions: (config) => dispatch(getCashierOptionsThunk(config)),
                            options: cashiers,
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'return-what',
                    label: t('Что возвращал'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'returnItemCategory',
                            label: t('Категория'),
                            filterTitle: t('Категория в-та'),
                            template: 'checkboxes-paginated-loader',
                            loadOptions: (config) => dispatch(getCategoryOptionsThunk(config)),
                            options: categories,
                        },
                        {
                            type: 'leaf',
                            id: 'returnItemSku',
                            label: t('Артикул'),
                            filterTitle: t('Артикул в-та'),
                            template: 'textField',
                        },
                        {
                            type: 'leaf',
                            id: 'returnItemSku2',
                            label: t('Наименование'),
                            filterTitle: t('Наименование в-та'),
                            template: 'checkboxes-paginated-loader',
                            loadOptions: (config) => dispatch(getProductOptionsThunk(config)),
                            options: paginatedProducts,
                        },
                    ],
                },
            ],
        },
    ];
    const isChildrenEnabled = (_b = (_a = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _a === void 0 ? void 0 : _a.features) === null || _b === void 0 ? void 0 : _b.children;
    if (!isChildrenEnabled)
        defaultItems.map((branch) => {
            if (branch.id === 'clients')
                branch.items = branch.items.filter((item) => item.id !== 'client-children');
            return branch;
        });
    const tree = [...customItems, ...defaultItems];
    return tree;
};
