import { jsx as _jsx } from "react/jsx-runtime";
import { $getNodeByKey, DecoratorNode, } from 'lexical';
import { VariableComponent } from './VariableComponent';
export class VariableNode extends DecoratorNode {
    constructor(id, label, payload, key) {
        super(key);
        this.onSetParam = (code, value, editor) => {
            editor.update(() => {
                const node = $getNodeByKey(this.getKey());
                if (node !== null && $isVariableNode(node))
                    node.setParam(code, value);
            });
        };
        this.__id = id;
        this.__label = label;
        this.__payload = payload;
    }
    static getType() {
        return 'variable';
    }
    static clone(node) {
        return new VariableNode(node.__id, node.__label, node.__payload, node.__key);
    }
    // rendering methods ---------------------------------------------------------
    decorate(editor) {
        return (_jsx(VariableComponent, Object.assign({ payload: this.__payload, onSetParam: (code, value) => {
                this.onSetParam(code, value, editor);
            } }, { children: this.__label })));
    }
    setParam(code, value) {
        const writable = this.getWritable();
        if (!writable.__payload.params)
            writable.__payload.params = {};
        writable.__payload.params[code] = value;
    }
    isInline() {
        return true;
    }
    isIsolated() {
        return true;
    }
    isKeyboardSelectable() {
        return true;
    }
    // DOM methods ---------------------------------------------------------------
    createDOM() {
        const element = document.createElement('span');
        return element;
    }
    updateDOM() {
        return false;
    }
    // JSON methods --------------------------------------------------------------
    exportJSON() {
        return {
            version: 1,
            type: 'variable',
            id: this.__id,
            label: this.__label,
            payload: this.__payload,
        };
    }
    static importJSON(serializedNode) {
        const node = $createVariableNode({
            id: serializedNode.id,
            label: serializedNode.label,
            key: serializedNode.payload.key,
            params: serializedNode.payload.params,
            parameters: serializedNode.payload.parameters,
        });
        return node;
    }
    // Text methods --------------------------------------------------------------
    getTextContent() {
        return `!${this.__id}`;
    }
}
export function $createVariableNode(variable) {
    const node = new VariableNode(variable.id, variable.label, {
        key: variable.key,
        params: variable.params,
        parameters: variable.parameters,
    });
    return node;
}
export function createSerializedVariableNode(variable) {
    return {
        version: 1,
        type: 'variable',
        id: variable.id,
        label: variable.label,
        payload: {
            key: variable.key,
            params: variable.params,
            parameters: variable.parameters,
        },
    };
}
export function $isVariableNode(node) {
    return node instanceof VariableNode;
}
