import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position } from '@xyflow/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FilterConditionsAlt } from '@/icons/flow/FilterConditionsAlt';
import { Start } from '@/icons/flow/Start';
import { NodeBody } from '../../helpers/NodeBody/NodeBody';
import { NodeRow } from '../../helpers/NodeRow/NodeRow';
import { NodeTitle } from '../../helpers/NodeTitle/NodeTitle';
import { NodeWrapper } from '../../helpers/NodeWrapper/NodeWrapper';
export const ConditionsNode = ({ data, selected, }) => {
    const { t } = useTranslation();
    const { type, header: providedHeader, conditions } = data;
    const icon = ['trigger', 'schedule'].includes(type) ? (_jsx(Start, {})) : (_jsx(FilterConditionsAlt, {}));
    const content = type === 'schedule' ? '' : getNodeHeader(conditions);
    const header = type === 'trigger' ? 'Условие старта' : 'Фильтр';
    return (_jsxs(_Fragment, { children: [_jsxs(NodeWrapper, Object.assign({ isSelected: selected, gap: '12' }, { children: [_jsx(NodeTitle, Object.assign({ icon: icon, hasSmallPadding: true }, { children: t(providedHeader || header) })), !!content && (_jsx(NodeBody, Object.assign({ hasSmallPadding: true, movedDown: true }, { children: t(content) }))), _jsx("div", { children: conditions.map((condition) => {
                            return (_jsx(NodeRow, Object.assign({ handleId: condition }, { children: _jsx("div", { dangerouslySetInnerHTML: {
                                        __html: type === 'schedule'
                                            ? condition
                                            : getConditionText(condition),
                                    } }) }), condition));
                        }) })] })), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target'), type: 'target', position: Position.Left })] }));
};
const getNodeHeader = (conditions) => {
    const first = conditions[0];
    const colon = first.indexOf(':');
    return first.slice(0, colon);
};
const getConditionText = (condition) => {
    const colon = condition.indexOf(':');
    return condition.slice(colon + 1).trim();
};
