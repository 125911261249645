import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { FormLoginPanel } from './FormLoginPanel';
import { FormLoginTitle } from './FormLoginTitle';
import styles from './styles.module.scss';
export const FormLogin = ({ onSubmit, error }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { isSubmitting, errors }, } = useForm({
        defaultValues: {
            username: '',
            password: '',
        },
    });
    const validateTrimValue = (value) => !value.trim() ? t('FIELD_REQUIRED_ERROR') : true;
    return (_jsx(FormLoginPanel, Object.assign({ title: _jsx(FormLoginTitle, {}) }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(FormFieldRow, Object.assign({ className: styles.control, help: (_a = errors.username) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.username, hasLabelHidden: true }, { children: _jsx(Controller, { name: 'username', control: control, rules: { validate: validateTrimValue }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'username', placeholder: t('логин'), hasError: !!errors.username, autoFocus: true }))) }) })), _jsx(FormFieldRow, Object.assign({ className: styles.control, help: (_b = errors.password) === null || _b === void 0 ? void 0 : _b.message, hasError: !!errors.password, hasLabelHidden: true }, { children: _jsx(Controller, { name: 'password', control: control, rules: { validate: validateTrimValue }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'password', type: 'password', placeholder: t('пароль'), hasError: !!errors.password }))) }) })), error && (_jsx(FormFieldRow, Object.assign({ className: styles.error, asErrorMessage: true, hasLabelHidden: true }, { children: error }))), _jsx(Button, Object.assign({ type: 'submit', theme: 'primary', disabled: isSubmitting, size: 'full-width' }, { children: t('Войти') }))] })) })));
};
