import { useTranslation } from 'react-i18next';
export const TRIGGER_TYPE_SEGMENT_JOIN = 0;
export const TRIGGER_TYPE_SEGMENT_LEAVE = 1;
export const TRIGGER_TYPE_PURCHASE = 6;
export const TRIGGER_TYPE_BONUS_ACTIVATE = 2;
export const TRIGGER_TYPE_RETURN = 3;
export const TRIGGER_TYPE_ABANDONED_CART = 8;
export const TRIGGER_TYPE_PRODUCT_VIEW = 9;
export const TRIGGER_TYPE_PRICE_IN_CART_DROPPED = 10;
export const TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED = 11;
export const TRIGGER_TYPE_BIRTHDAY = 4;
export const TRIGGER_TYPE_CHILD_BIRTHDAY = 7;
export const TRIGGER_TYPE_BONUS_EXPIRING = 5;
export const TRIGGER_TYPE_REGISTER_CLIENT = 12;
export const TRIGGER_TYPE_REGISTER_WALLET_CARD = 13;
export const TRIGGER_TYPE_SUBSCRIBE_EMAIL = 14;
export const TRIGGER_TYPE_SCHEDULE = 15;
export const TRIGGER_TYPE_LEVEL_UP = 16;
export const TRIGGER_TYPE_EMAIL_DELIVERED = 17;
export const TRIGGER_TYPES_MAP = {
    [TRIGGER_TYPE_SEGMENT_JOIN]: 'TRIGGER_TYPE_SEGMENT_JOIN',
    [TRIGGER_TYPE_SEGMENT_LEAVE]: 'TRIGGER_TYPE_SEGMENT_LEAVE',
    [TRIGGER_TYPE_PURCHASE]: 'TRIGGER_TYPE_PURCHASE',
    [TRIGGER_TYPE_BONUS_ACTIVATE]: 'TRIGGER_TYPE_BONUS_ACTIVATE',
    [TRIGGER_TYPE_RETURN]: 'TRIGGER_TYPE_RETURN',
    [TRIGGER_TYPE_ABANDONED_CART]: 'TRIGGER_TYPE_ABANDONED_CART',
    [TRIGGER_TYPE_PRODUCT_VIEW]: 'TRIGGER_TYPE_PRODUCT_VIEW',
    [TRIGGER_TYPE_PRICE_IN_CART_DROPPED]: 'TRIGGER_TYPE_PRICE_IN_CART_DROPPED',
    [TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED]: 'TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED',
    [TRIGGER_TYPE_BIRTHDAY]: 'TRIGGER_TYPE_BIRTHDAY',
    [TRIGGER_TYPE_CHILD_BIRTHDAY]: 'TRIGGER_TYPE_CHILD_BIRTHDAY',
    [TRIGGER_TYPE_BONUS_EXPIRING]: 'TRIGGER_TYPE_BONUS_EXPIRING',
    [TRIGGER_TYPE_REGISTER_CLIENT]: 'TRIGGER_TYPE_REGISTER_CLIENT',
    [TRIGGER_TYPE_REGISTER_WALLET_CARD]: 'TRIGGER_TYPE_REGISTER_WALLET_CARD',
    [TRIGGER_TYPE_SUBSCRIBE_EMAIL]: 'TRIGGER_TYPE_SUBSCRIBE_EMAIL',
    [TRIGGER_TYPE_SCHEDULE]: 'TRIGGER_TYPE_SCHEDULE',
    [TRIGGER_TYPE_LEVEL_UP]: 'TRIGGER_TYPE_LEVEL_UP',
    [TRIGGER_TYPE_EMAIL_DELIVERED]: 'TRIGGER_TYPE_EMAIL_DELIVERED',
};
export const useOptions = (isBeforeEvents, hasRecommendationsFeature, hasLevelsFeature) => {
    const { t } = useTranslation();
    const segmentEvents = [
        {
            id: 'segment-events',
            label: t('Сегмент'),
            isGroup: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        },
        {
            id: TRIGGER_TYPE_SEGMENT_JOIN,
            label: t('попадания в сегмент'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        },
        {
            id: TRIGGER_TYPE_SEGMENT_LEAVE,
            label: t('покидания сегмента'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        },
    ];
    const loyaltyEvents = [
        {
            id: 'loyalty-events',
            label: t('Лояльность'),
            isGroup: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        },
        {
            id: TRIGGER_TYPE_REGISTER_CLIENT,
            label: t('регистрации в ПЛ'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        },
        {
            id: TRIGGER_TYPE_REGISTER_WALLET_CARD,
            label: t('установки карты Wallet'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        },
    ];
    if (hasLevelsFeature)
        loyaltyEvents.push({
            id: TRIGGER_TYPE_LEVEL_UP,
            label: t('достижения нового уровня'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        });
    const mailingEvents = [
        {
            id: 'mailing-events',
            label: t('Рассылки'),
            isGroup: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        },
        {
            id: TRIGGER_TYPE_EMAIL_DELIVERED,
            label: t('получения Email-рассылки'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        },
    ];
    const purchaseEvents = [
        {
            id: 'purchase-events',
            label: t('Покупка'),
            isGroup: true,
            isDisabled: false,
            isLocked: false,
        },
        {
            id: TRIGGER_TYPE_PURCHASE,
            label: t('совершения покупки'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        },
        {
            id: TRIGGER_TYPE_BONUS_ACTIVATE,
            label: t('активации бонусов'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        },
        {
            id: TRIGGER_TYPE_BONUS_EXPIRING,
            label: t('сгорания бонусов'),
            isGroupItem: true,
            isDisabled: false,
            isLocked: false,
        },
        {
            id: TRIGGER_TYPE_RETURN,
            label: t('возврата'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        },
    ];
    const websiteEvents = [
        {
            id: 'website-events',
            label: t('События сайта'),
            isGroup: true,
            isDisabled: isBeforeEvents,
            isLocked: !hasRecommendationsFeature,
        },
        {
            id: TRIGGER_TYPE_ABANDONED_CART,
            label: t('ухода с сайта (брошенная корзина)'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: !hasRecommendationsFeature,
        },
        {
            id: TRIGGER_TYPE_PRODUCT_VIEW,
            label: t('ухода с сайта (брошенный просмотр товара или категории)'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: !hasRecommendationsFeature,
        },
        {
            id: TRIGGER_TYPE_PRICE_IN_CART_DROPPED,
            label: t('снижения цены на товар в корзине'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: !hasRecommendationsFeature,
        },
        {
            id: TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED,
            label: t('снижения цены на товар в избранном'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: !hasRecommendationsFeature,
        },
        {
            id: TRIGGER_TYPE_SUBSCRIBE_EMAIL,
            label: t('подписки на Email-рассылку'),
            isGroupItem: true,
            isDisabled: isBeforeEvents,
            isLocked: false,
        },
    ];
    const clientEvents = [
        {
            id: 'client-events',
            label: t('Клиенты'),
            isGroup: true,
            isDisabled: false,
            isLocked: false,
        },
        {
            id: TRIGGER_TYPE_BIRTHDAY,
            label: t('дня рождения'),
            isGroupItem: true,
            isDisabled: false,
            isLocked: false,
        },
        {
            id: TRIGGER_TYPE_CHILD_BIRTHDAY,
            label: t('дня рождения ребенка'),
            isGroupItem: true,
            isDisabled: false,
            isLocked: false,
        },
    ];
    const options = [
        ...segmentEvents,
        ...loyaltyEvents,
        ...mailingEvents,
        ...purchaseEvents,
        ...websiteEvents,
        ...clientEvents,
    ];
    return options;
};
