var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetTransactionPurchaseQuery } from '@/api/query/purchases';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { AutocompleteCashiers } from '@/components/Autocomplete/instances/AutocompleteCashiers';
import { DateInput } from '@/components/DateInput/DateInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { buildClientFullName } from '@/utils/getClientFullName';
import { addToLS, getFromLS } from '@/utils/LS';
import { formatPhoneNumber } from '@/utils/phoneNumber';
import { FormPurchaseWithDetailsAmont } from './FormPurchaseWithDetailsAmont';
import { FormPurchaseWithDetailsTabs } from './FormPurchaseWithDetailsTabs';
import styles from './styles.module.scss';
export const FormPurchaseWithDetails = memo(({ id, shops = [], currency, onChangeSubmitting, onUpdated, setHasFormChanged, getCashiers = () => __awaiter(void 0, void 0, void 0, function* () { return []; }), updatePurchase = () => __awaiter(void 0, void 0, void 0, function* () { return ''; }), selectedPurchase = null, selectedClient = null, 
// Сделано для проброса данных сверху для сторис компонента
transactionPurchaseData, onError, }) => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const shopIdFromLS = getFromLS({ key: 'lastPurchaseShopId' });
    const shopFromLS = shops.find((shop) => shop.id == shopIdFromLS);
    const { data: responseData, isLoading, isFetching, } = useGetTransactionPurchaseQuery({ id: (selectedPurchase === null || selectedPurchase === void 0 ? void 0 : selectedPurchase.id) || 0 }, { skip: !(selectedPurchase === null || selectedPurchase === void 0 ? void 0 : selectedPurchase.id) });
    const data = transactionPurchaseData || responseData;
    const cashierName = (selectedPurchase === null || selectedPurchase === void 0 ? void 0 : selectedPurchase.cashier) || (data === null || data === void 0 ? void 0 : data.purchase.cashier);
    const cashierCode = (selectedPurchase === null || selectedPurchase === void 0 ? void 0 : selectedPurchase.cashierId) || (data === null || data === void 0 ? void 0 : data.purchase.cashierId);
    const cashier = cashierCode !== null && cashierCode !== undefined
        ? { name: cashierName, code: cashierCode }
        : null;
    const { control, handleSubmit, formState, setValue, getValues, reset } = useForm({
        defaultValues: {
            executedAt: selectedPurchase
                ? moment(selectedPurchase.executedAt).format('DD.MM.YYYY')
                : moment().format('DD.MM.YYYY'),
            shopId: selectedPurchase
                ? selectedPurchase.shop.id
                : (shopFromLS === null || shopFromLS === void 0 ? void 0 : shopFromLS.id) || ((_a = shops[0]) === null || _a === void 0 ? void 0 : _a.id),
            cashier,
        },
    });
    useEffect(() => {
        setHasFormChanged(false);
    }, []);
    const { errors, isSubmitted, isSubmitting, dirtyFields } = formState;
    useEffect(() => {
        if (setHasFormChanged)
            setHasFormChanged((prevValue) => prevValue || (!isSubmitting && !!Object.keys(dirtyFields).length));
    }, [formState]);
    const client = selectedPurchase ? selectedPurchase.client : selectedClient;
    useEffect(() => {
        var _a;
        reset({
            executedAt: selectedPurchase
                ? moment(selectedPurchase.executedAt).format('DD.MM.YYYY')
                : moment().format('DD.MM.YYYY'),
            shopId: selectedPurchase
                ? selectedPurchase.shop.id
                : (shopFromLS === null || shopFromLS === void 0 ? void 0 : shopFromLS.id) || ((_a = shops[0]) === null || _a === void 0 ? void 0 : _a.id),
            cashier,
        });
    }, [selectedPurchase]);
    const handleUpdatePurchase = () => __awaiter(void 0, void 0, void 0, function* () {
        var _f;
        if (selectedPurchase) {
            const values = getValues();
            onChangeSubmitting === null || onChangeSubmitting === void 0 ? void 0 : onChangeSubmitting(true);
            const error = yield updatePurchase({
                purchaseId: selectedPurchase.id,
                executedAt: values.executedAt,
                shopId: values.shopId || 0,
                cashierId: ((_f = values === null || values === void 0 ? void 0 : values.cashier) === null || _f === void 0 ? void 0 : _f.id) || '',
            });
            onChangeSubmitting === null || onChangeSubmitting === void 0 ? void 0 : onChangeSubmitting(false);
            if (error)
                onError(error);
            else {
                const values = getValues();
                addToLS({ key: 'lastPurchaseShopId', value: values.shopId });
                onUpdated === null || onUpdated === void 0 ? void 0 : onUpdated();
            }
        }
    });
    const isList = !!(data === null || data === void 0 ? void 0 : data.purchase.items.length);
    return (_jsxs("form", Object.assign({ id: id, onSubmit: handleSubmit(() => {
            handleUpdatePurchase();
        }) }, { children: [_jsxs("div", Object.assign({ className: styles.formContent }, { children: [client && (_jsxs("div", Object.assign({ className: styles.clientInfo }, { children: [_jsx("span", { children: t('Клиент') }), _jsxs("span", Object.assign({ className: styles.client }, { children: [buildClientFullName(client), client.phoneNumber
                                        ? `, ${formatPhoneNumber(client.phoneNumber)}`
                                        : ''] }))] }))), _jsxs("div", Object.assign({ className: styles.controls }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Дата покупки'), labelFor: 'executedAt', controlSize: '150', help: (_b = errors.executedAt) === null || _b === void 0 ? void 0 : _b.message, hasError: !!errors.executedAt, labelClassName: styles.fieldRowLabel, className: styles.fieldRow, hasNoMargin: true }, { children: _jsx(Controller, { name: 'executedAt', control: control, rules: {
                                        required: {
                                            value: true,
                                            message: t('FIELD_REQUIRED_ERROR'),
                                        },
                                    }, render: ({ field }) => (_jsx(DateInput, { id: 'executedAt', selected: field.value
                                            ? moment(field.value, 'DD.MM.YYYY').toDate()
                                            : null, onChange: (date) => {
                                            setValue('executedAt', date ? moment(date).format('DD.MM.YYYY') : '', { shouldValidate: isSubmitted, shouldDirty: true });
                                        }, hasError: !!errors.executedAt, endYear: 2040 })) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Место покупки'), labelFor: 'shopId', help: (_c = errors.shopId) === null || _c === void 0 ? void 0 : _c.message, hasError: !!errors.shopId, isRequired: true, labelClassName: styles.fieldRowLabel, className: styles.fieldRow, controlClassName: styles.shopIdField, hasNoMargin: true }, { children: _jsx(Controller, { name: 'shopId', control: control, rules: {
                                        required: {
                                            value: true,
                                            message: t('FIELD_REQUIRED_ERROR'),
                                        },
                                    }, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'shopId', placeholder: t('выберите место покупки'), value: shops.find((shop) => shop.id === field.value) || null, onChange: (shop) => {
                                            setValue('shopId', (shop === null || shop === void 0 ? void 0 : shop.id) || null, {
                                                shouldValidate: isSubmitted,
                                                shouldDirty: true,
                                            });
                                        }, options: shops, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.name, hasError: !!errors.shopId, hasResetButton: false }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Кассир'), labelFor: 'cashier', help: (_d = errors.shopId) === null || _d === void 0 ? void 0 : _d.message, hasError: !!errors.shopId, labelClassName: styles.fieldRowLabel, className: styles.fieldRow, controlClassName: styles.cashierIdField, hasNoMargin: true }, { children: _jsx(Controller, { name: 'cashier', control: control, render: ({ field }) => {
                                        return (_jsx(AutocompleteCashiers, { id: 'cashier', value: field.value, onChange: (value) => setValue('cashier', value, {
                                                shouldDirty: true,
                                            }), hasError: !!errors.client, options: getCashiers, hasResetButton: false, shouldLoadInitialData: !field.value }));
                                    } }) }))] }))] })), _jsx(FormPurchaseWithDetailsTabs, { isLoading: isFetching, purchase: data === null || data === void 0 ? void 0 : data.purchase, currency: currency }), !isList && !isFetching && _jsx("div", { className: styles.divider }), !isLoading && !isFetching && (_jsx(FormPurchaseWithDetailsAmont, { currency: currency, promocodeAmountSum: data === null || data === void 0 ? void 0 : data.purchase.promocodeDiscount, totalDiscount: data === null || data === void 0 ? void 0 : data.purchase.totalDiscount, giftCardDiscount: (_e = data === null || data === void 0 ? void 0 : data.purchase) === null || _e === void 0 ? void 0 : _e.giftCardDiscount, totalAmount: data === null || data === void 0 ? void 0 : data.purchase.totalAmount, bonusesApplied: data === null || data === void 0 ? void 0 : data.purchase.bonusesApplied, paidAmount: data === null || data === void 0 ? void 0 : data.purchase.paidAmount, bonusesCollected: data === null || data === void 0 ? void 0 : data.purchase.bonusesCollected }))] })));
});
