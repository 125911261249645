import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Popover from '@mui/material/Popover';
import { cloneElement, useEffect, useRef } from 'react';
import { CloseModal } from '@/icons/CloseModal';
import styles from './styles.module.scss';
const focusOnModal = () => {
    var _a, _b;
    const modal = (_b = (_a = document.getElementById('modal-of-app')) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b[2];
    // сами ставим фокус для корректной работы с escape
    modal === null || modal === void 0 ? void 0 : modal.focus({
        preventScroll: true,
    });
};
export const CloseButton = ({ isOnMountFocus = false, tooltipOpen, notificationOfClosure, onClose, setTooltipOpen, handleClose, }) => {
    useEffect(() => {
        isOnMountFocus && focusOnModal();
    }, []);
    if (notificationOfClosure) {
        const tooltipTitle = cloneElement(notificationOfClosure, {
            handleCancel: () => {
                if (onClose)
                    onClose();
                setTooltipOpen(false);
            },
            handleAgree: () => setTooltipOpen(false),
        });
        useEffect(() => {
            const handleEscape = (event) => {
                if (event.key === 'Escape') {
                    setTooltipOpen(false);
                    focusOnModal();
                }
            };
            addEventListener('keydown', handleEscape);
            return () => removeEventListener('keydown', handleEscape);
        }, []);
        const anchor = useRef(null);
        return (_jsxs(_Fragment, { children: [_jsx("button", Object.assign({ ref: anchor, className: styles.close, onClick: handleClose }, { children: _jsx(CloseModal, {}) })), _jsx(Popover, Object.assign({ open: tooltipOpen, anchorEl: anchor.current, onClose: () => {
                        setTooltipOpen(false);
                        focusOnModal();
                    }, anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    }, transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }, className: styles.notificationOfClosure, transitionDuration: 200, disableAutoFocus: true, disableEnforceFocus: true, disableRestoreFocus: true }, { children: tooltipTitle }))] }));
    }
    return (_jsx("button", Object.assign({ className: styles.close, onClick: onClose }, { children: _jsx(CloseModal, {}) })));
};
