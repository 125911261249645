import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { QRCodeSVG } from 'qrcode.react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteClients } from '@/components/Autocomplete/instances/AutocompleteClients';
import { Button } from '@/components/Button2/Button';
import { Modal } from '@/components/Modal/Modal';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { getDeviceType } from '@/utils/getDeviceType';
import styles from './styles.module.scss';
export const ClientWalletsLinkModal = ({ isOpen, onClose, getClients, deviceType = getDeviceType(), }) => {
    const { t } = useTranslation();
    const [selectedClient, setSelectedClient] = useState(null);
    const link = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.walletsLink;
    const copyToClipboard = useCopyToClipboard({ showToast: true });
    return (_jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, title: t('Установка карты на телефон'), size: '550', bodyClassName: styles.body }, { children: [_jsxs("div", Object.assign({ className: styles.item }, { children: [_jsx("div", Object.assign({ className: styles.counter }, { children: "1." })), _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: t('Выберите клиента, чтобы получить ссылку на его карту') })), _jsx("div", Object.assign({ className: styles.clientsSelect }, { children: _jsx(AutocompleteClients, { value: selectedClient, onChange: setSelectedClient, getClients: getClients, showClientLevel: true, shouldLoadInitialData: true }) }))] }))] })), !!link && (_jsxs("div", Object.assign({ className: styles.item }, { children: [_jsx("div", Object.assign({ className: styles.counter }, { children: "2." })), _jsxs("div", Object.assign({ className: styles.content }, { children: [deviceType !== 'mobile' && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: t('Отсканируйте QR-код') })), _jsx("div", Object.assign({ className: styles.qr }, { children: _jsx(QRCodeSVG, { value: link, size: 185, fgColor: '#3a3a3a' }) }))] })), _jsx("div", Object.assign({ className: styles.linkTitle }, { children: deviceType === 'mobile'
                                    ? t('Откройте ссылку в браузере')
                                    : t('Или отправьте ссылку на телефон, чтобы открыть ее в мобильном браузере:') })), _jsx("div", Object.assign({ className: styles.link }, { children: _jsx("a", Object.assign({ href: link, target: '_blank', rel: 'noreferrer' }, { children: link })) })), _jsx("div", { children: _jsx(Button, Object.assign({ type: 'button', onClick: () => copyToClipboard(link) }, { children: t('Скопировать ссылку') })) })] }))] })))] })));
};
