import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { OffersTab } from './OffersTab/OffersTab';
import { ReturnTab } from './ReturnTab/ReturnTab';
import { SpecificationsTab } from './SpecificationsTab/SpecificationsTab';
import { StructureTab } from './StructureTab/StructureTab';
import styles from './styles.module.scss';
var Tabs;
(function (Tabs) {
    Tabs["STRUCTURE"] = "structure";
    Tabs["SPECIFICATIONS"] = "specifications";
    Tabs["OFFERS"] = "offers";
    Tabs["RETURN"] = "return";
})(Tabs || (Tabs = {}));
export const FormPurchaseWithDetailsTabs = memo(({ isLoading, purchase, currency }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(Tabs.STRUCTURE);
    const tabs = [
        { id: Tabs.STRUCTURE, label: t('Состав') },
        { id: Tabs.SPECIFICATIONS, label: t('Характеристики') },
        { id: Tabs.OFFERS, label: t('Акции') },
    ];
    if (purchase === null || purchase === void 0 ? void 0 : purchase.returns.length)
        tabs.push({ id: Tabs.RETURN, label: t('Возврат') });
    if (!isLoading && !(purchase === null || purchase === void 0 ? void 0 : purchase.items.length))
        return null;
    return (_jsxs(TabsPanel, Object.assign({ isContentSkeleton: isLoading, headerClassName: styles.tabsHeader, className: styles.tabs, bodyClassName: styles.tabsBody, tabs: tabs, activeTabId: activeTab, onChangeActiveTab: (tabId) => setActiveTab(tabId) }, { children: [activeTab === Tabs.STRUCTURE && (_jsx(StructureTab, { currency: currency, purchase: purchase })), activeTab === Tabs.OFFERS && (_jsx(OffersTab, { currency: currency, purchase: purchase })), activeTab === Tabs.SPECIFICATIONS && (_jsx(SpecificationsTab, { purchase: purchase, currency: currency })), activeTab === Tabs.RETURN && (_jsx(ReturnTab, { purchase: purchase, currency: currency }))] })));
});
