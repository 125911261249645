import { ChannelNode } from './ChannelNode/ChannelNode';
import { ConditionsNode } from './ConditionsNode/ConditionsNode';
import { DelayNode } from './DelayNode/DelayNode';
import { EmailReceivedNode } from './EmailReceived/EmailReceived';
import { LoopNode } from './LoopNode/LoopNode';
import { TriggerNode } from './TriggerNode/TriggerNode';
export const nodeTypes = {
    trigger: TriggerNode,
    conditions: ConditionsNode,
    channel: ChannelNode,
    delay: DelayNode,
    'email-received': EmailReceivedNode,
    loop: LoopNode,
};
