import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Pagination } from '@/components/Pagination/Pagination';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Download } from '@/icons/Download';
import { Plus } from '@/icons/Plus';
import { numberRound } from '@/utils/numbers';
import { PurchasesTable } from './PurchasesTable';
export const PurchasesPanel = memo(({ count, purchases = [], currency, isLoading, isFetching, onRowClick, onOpenPurchaseAddForm, onExportXlsx, limit, offset, onChangeOffset, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasExportManage = userHasAccessTo('EXPORTS_MANAGE');
    const userHasPurchasesManage = userHasAccessTo('CLIENTS_PURCHASES_MANAGE');
    const toolbar = (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: !userHasExportManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ icon: _jsx(Download, {}), title: t('Выгрузить в Excel'), onClick: onExportXlsx, hasIconMargin: false, disabled: !userHasExportManage }, { children: "XLS" })) })), _jsx(Tooltip, Object.assign({ title: !userHasPurchasesManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ icon: _jsx(Plus, {}), onClick: onOpenPurchaseAddForm, theme: 'primary', size: 'extra-large', disabled: !userHasPurchasesManage }, { children: t('Добавить покупку') })) }))] }));
    const pagination = !!count && (_jsx(Pagination, { total: count, limit: limit, offset: offset, onChangeOffset: onChangeOffset }));
    return (_jsx(TabsPanel, Object.assign({ tabs: [
            {
                id: 'purchases',
                label: t('PURCHASES_PANEL_HEADER', {
                    amount: numberRound(count || 0),
                    count: count || 0,
                }),
            },
        ], rightToolbar: toolbar, footer: pagination, isSkeleton: isLoading }, { children: _jsx(PurchasesTable, { purchases: purchases, currency: currency, isLoading: isFetching, onRowClick: onRowClick }) })));
});
