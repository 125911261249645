export const createCascadeNode = (mailing) => {
    const channels = [];
    if (mailing.channels.push)
        channels.push('push');
    if (mailing.channels.viber)
        channels.push('viber');
    if (mailing.channels.sms)
        channels.push('sms');
    if (channels.length)
        return {
            id: `${channels.join('-')}-${mailing.id}`,
            type: 'channel',
            data: {
                channels,
                content: mailing.name,
                status: !mailing.isActive ? 'pause' : 'active',
                mailing,
                hasOutput: mailing.channels.email || mailing.channels.telegram,
            },
            position: { x: 0, y: 0 },
        };
    return null;
};
export const createEmailNode = (mailing, hasOutput) => {
    if (!mailing.channels.email)
        return null;
    return {
        id: `email-${mailing.id}`,
        type: 'channel',
        data: {
            channels: ['email'],
            content: mailing.name,
            status: !mailing.isActive ? 'pause' : 'active',
            mailing,
            hasOutput,
        },
        position: { x: 0, y: 0 },
    };
};
export const createTelegramNode = (mailing, hasOutput) => {
    if (!mailing.channels.telegram)
        return null;
    return {
        id: `telegram-${mailing.id}`,
        type: 'channel',
        data: {
            channels: ['telegram'],
            content: mailing.name,
            status: !mailing.isActive ? 'pause' : 'active',
            mailing,
            hasOutput,
        },
        position: { x: 0, y: 0 },
    };
};
export const createChannelNodes = (branches, emailReceivedMailings) => {
    const channelNodes = [];
    const channelEdges = [];
    for (const fromNodeId of Object.keys(branches)) {
        const handles = branches[fromNodeId];
        for (const fromHandleId of Object.keys(handles)) {
            const mailings = handles[fromHandleId];
            for (let i = 0; i < mailings.length; i += 1) {
                const mailing = mailings[i];
                const source = fromNodeId;
                const sourceHandle = fromHandleId !== 'no-handle' ? fromHandleId : undefined;
                const cascadeNode = createCascadeNode(mailing);
                if (cascadeNode) {
                    channelNodes.push(cascadeNode);
                    channelEdges.push({
                        id: `from-${fromNodeId}-to-${cascadeNode.id}`,
                        source,
                        sourceHandle,
                        target: cascadeNode.id,
                    });
                }
                const telegramNode = createTelegramNode(mailing, false);
                const emailReceivedMailing = emailReceivedMailings.find((m) => { var _a, _b; return ((_b = (_a = m.triggerConditions) === null || _a === void 0 ? void 0 : _a.conditions) === null || _b === void 0 ? void 0 : _b.mailingBrandEq) === mailing.id; });
                const emailNode = createEmailNode(mailing, !!emailReceivedMailing || !!telegramNode);
                if (emailNode) {
                    channelNodes.push(emailNode);
                    channelEdges.push({
                        id: cascadeNode
                            ? `from-${cascadeNode.id}-to-${emailNode.id}`
                            : `from-${fromNodeId}-to-${emailNode.id}`,
                        source: cascadeNode ? cascadeNode.id : source,
                        sourceHandle: cascadeNode ? undefined : sourceHandle,
                        target: emailNode.id,
                    });
                }
                if (telegramNode) {
                    channelNodes.push(telegramNode);
                    channelEdges.push({
                        id: emailNode
                            ? `from-${emailNode.id}-to-${telegramNode.id}`
                            : cascadeNode
                                ? `from-${cascadeNode.id}-to-${telegramNode.id}`
                                : `from-${fromNodeId}-to-${telegramNode.id}`,
                        source: emailNode
                            ? emailNode.id
                            : cascadeNode
                                ? cascadeNode.id
                                : source,
                        sourceHandle: emailNode || cascadeNode ? undefined : sourceHandle,
                        target: telegramNode.id,
                    });
                }
            }
        }
    }
    return { channelNodes, channelEdges };
};
