var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getErrorMessage } from '@/api/query';
import { useCreateExportMutation, } from '@/api/query/exports';
import { ExportTemplateType } from '@/components/ExportTemplatesTable/ExportTemplatesTable';
import { useToastContext } from '@/components/Toast/ToastContext';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useExportXlsx = (_a) => {
    var { onSuccess } = _a, payload = __rest(_a, ["onSuccess"]);
    const addToast = useToastContext();
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const [createExport] = useCreateExportMutation();
    return () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield createExport(Object.assign({ type: ExportTemplateType.PURCHASE, periodStart,
            periodEnd }, payload));
        if ('error' in result)
            addToast({
                type: 'error',
                message: getErrorMessage(result.error),
            });
        else
            onSuccess();
    });
};
