var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useSaveImportMutation } from '@/api/query/import';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useSaveImport = (options) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [saveImport, { isLoading }] = useSaveImportMutation();
    const [error, setError] = useState('');
    const handleSaveImport = (data, isDraft) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const result = yield saveImport(Object.assign(Object.assign({}, data), { isDraft }));
        if ('error' in result)
            setError(getErrorMessage(result.error));
        else {
            addToast({ type: 'success', message: t('Импорт изменён') });
            (_a = options === null || options === void 0 ? void 0 : options.onSuccess) === null || _a === void 0 ? void 0 : _a.call(options);
        }
    });
    return {
        saveImport: handleSaveImport,
        isSaving: isLoading,
        error,
    };
};
