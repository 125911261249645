var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { cloneElement, forwardRef, useCallback } from 'react';
import styles from './styles.module.scss';
export const Input = forwardRef((_a, ref) => {
    var { wrapperClassName, hasError = false, leftElement, leftIcon, leftText, rightElement, rightButton, rightButtonClassName, rightIcon, rightText, shouldHideSpinButtons = false, shouldHideOverflow = false, preventChars, preventNegativeNumbers, isInline = false, isActive = false, className, type, placeholder, disabled, value, onKeyDown, onWheel } = _a, props = __rest(_a, ["wrapperClassName", "hasError", "leftElement", "leftIcon", "leftText", "rightElement", "rightButton", "rightButtonClassName", "rightIcon", "rightText", "shouldHideSpinButtons", "shouldHideOverflow", "preventChars", "preventNegativeNumbers", "isInline", "isActive", "className", "type", "placeholder", "disabled", "value", "onKeyDown", "onWheel"]);
    const forwardFocusFromLeft = useCallback((e) => {
        const input = e.currentTarget.nextSibling;
        input === null || input === void 0 ? void 0 : input.focus();
    }, []);
    const forwardFocusFromRight = useCallback((e) => {
        const input = e.currentTarget.previousSibling;
        input === null || input === void 0 ? void 0 : input.focus();
    }, []);
    const handleKeyDown = useCallback((e) => {
        if (preventChars === null || preventChars === void 0 ? void 0 : preventChars.includes(e.key))
            e.preventDefault();
        if (preventNegativeNumbers) {
            if (e.key === '-')
                e.preventDefault();
            if (e.key === 'ArrowDown' &&
                'value' in e.target &&
                Number(e.target.value) <= 0)
                e.preventDefault();
        }
        if (onKeyDown)
            onKeyDown(e);
    }, [preventChars, preventNegativeNumbers, onKeyDown]);
    const handleWheel = useCallback((e) => {
        if (preventNegativeNumbers)
            e.currentTarget.blur();
        if (onWheel)
            onWheel(e);
    }, [preventNegativeNumbers, onWheel]);
    return (_jsx("div", Object.assign({ className: classNames(styles.wrapper, { [styles.error]: hasError }, wrapperClassName) }, { children: _jsxs("div", Object.assign({ className: classNames(styles.inputWrapper, {
                [styles.disabled]: disabled,
                [styles.inline]: isInline,
                [styles.active]: isActive,
                [styles.leftIcon]: !!leftIcon,
                [styles.leftText]: !!leftText,
                [styles.rightElement]: !!rightElement,
                [styles.rightButton]: !!rightButton,
                [styles.rightIcon]: !!rightIcon,
                [styles.rightText]: !!rightText,
            }) }, { children: [!!leftElement && (_jsx("div", Object.assign({ className: styles.left, onClick: forwardFocusFromLeft }, { children: leftElement }))), !!leftIcon && (_jsx("div", Object.assign({ className: styles.left, onClick: forwardFocusFromLeft }, { children: leftIcon }))), !!leftText && (_jsx("div", Object.assign({ className: styles.left, onClick: forwardFocusFromLeft }, { children: leftText }))), _jsx("input", Object.assign({ ref: ref, className: classNames(styles.input, {
                        [styles.asButton]: type === 'button',
                        [styles.empty]: type === 'button' && !value,
                        [styles.hideSpinButtons]: shouldHideSpinButtons,
                        [styles.hideOverflow]: shouldHideOverflow,
                    }, className), type: type, placeholder: placeholder, disabled: disabled, value: type === 'button' && !value ? placeholder : value, onKeyDown: preventChars || preventNegativeNumbers ? handleKeyDown : onKeyDown, onWheel: handleWheel }, props)), !!rightElement && _jsx("div", Object.assign({ className: styles.right }, { children: rightElement })), !!rightButton && (_jsx("div", Object.assign({ className: styles.right }, { children: cloneElement(rightButton, {
                        className: classNames(styles.button, rightButtonClassName),
                    }) }))), !!rightIcon && (_jsx("div", Object.assign({ className: styles.right, onClick: forwardFocusFromRight }, { children: rightIcon }))), !!rightText && (_jsx("div", Object.assign({ className: styles.right, onClick: forwardFocusFromRight }, { children: rightText })))] })) })));
});
