import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { rejectUploadReason } from '@/components/FileUploadInput/rejectUploadReason';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { ImageCropInput } from '@/components/ImageCropInput/ImageCropInput';
import styles from '../styles.module.scss';
export const FormMailingViberImage = ({ uploadImage, labelWidth, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, setValue, setError, formState } = useFormContext();
    const { errors } = formState;
    return (_jsx(FormFieldRow, Object.assign({ className: styles.imageRow, label: t('Картинка'), labelFor: 'viberImage', labelWidth: labelWidth, help: (_a = errors.viberImage) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.viberImage }, { children: _jsx(Controller, { name: 'viberImage', control: control, render: ({ field }) => (_jsx(ImageCropInput, { id: 'viberImage', value: field.value || null, onChange: (file) => {
                    setValue('viberImage', file, {
                        shouldValidate: true,
                        shouldDirty: true,
                    });
                    if (file && uploadImage)
                        uploadImage({ file }).then((payload) => {
                            setValue('viberImageData', payload);
                        });
                }, onReject: (reason) => {
                    const message = rejectUploadReason(reason, '', 'square');
                    if (message)
                        setError('viberImage', { message: t(message) });
                    setValue('viberImage', null);
                }, previewWidth: 192, previewHeight: 192, description: t('Квадратная, до 300кб'), constraints: {
                    mimeType: 'image/*',
                    maxSize: 300000,
                    shape: 'square',
                }, cropModalTitle: t('Картинка'), hasError: !!errors.viberImage, theme: 'outlined' })) }) })));
};
