import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button2/Button';
import styles from './styles.module.scss';
export const NotificationOfClosureModalForForm = memo(({ handleAgree, handleCancel }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx("div", { className: styles.arrow }), _jsxs("div", Object.assign({ className: styles.info }, { children: [_jsx("span", Object.assign({ className: styles.title }, { children: t('Уйти без сохранения?') })), _jsx("span", Object.assign({ className: styles.text }, { children: t('Если введенные данные важны, сохраните их перед уходом с формы') }))] })), _jsxs("div", Object.assign({ className: styles.actions }, { children: [_jsx(Button, Object.assign({ theme: 'danger', size: 'full-width', onClick: (event) => {
                            event.stopPropagation();
                            if (handleCancel)
                                handleCancel();
                        } }, { children: t('Уйти') })), _jsx(Button, Object.assign({ theme: 'default', size: 'full-width', onClick: (event) => {
                            event.stopPropagation();
                            if (handleAgree)
                                handleAgree();
                        } }, { children: t('Остаться на форме') }))] }))] })));
});
