import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Table } from '@/components/Table/Table';
import { convertFlowsToTable } from './convertFlowsToTable';
import { FlowsTableEmpty } from './FlowsTableEmpty';
import styles from './styles.module.scss';
import { useOpenPurchaseFilter } from './useOpenPurchaseFilter';
export const FlowsTable = ({ flows, isLoading, onCreateFlow, onEditFlow, onDeleteFlow, }) => {
    const { t } = useTranslation();
    const { currency, shouldDisplayProfit } = useCurrentUser();
    const { purchaseUrl, openPurchaseFilter } = useOpenPurchaseFilter();
    const userHasAccessTo = useUserPermissions();
    const userHasFlowManage = userHasAccessTo('FLOWS_MANAGE');
    const columns = [
        {
            id: 'title',
            title: t('Название'),
        },
        {
            id: 'channels',
            title: t('Каналы'),
            tooltip: t('TOOLTIP_MAILING_CHANNELS'),
        },
        {
            id: 'roi',
            title: t('ROI'),
            tooltip: t(`TOOLTIP_ROI_${shouldDisplayProfit ? 'PROFIT' : 'REVENUE'}`),
            align: 'right',
        },
        {
            id: 'cr',
            title: t('CR'),
            tooltip: t('TOOLTIP_MAILING_CR'),
            align: 'right',
        },
        {
            id: 'profitOrRevenue',
            title: t(shouldDisplayProfit ? 'Прибыль' : 'Выручка'),
            tooltip: t(`TOOLTIP_MAILING_${shouldDisplayProfit ? 'PROFIT' : 'REVENUE'}`),
            align: 'right',
        },
        {
            id: 'expenses',
            title: t('Расходы'),
            tooltip: t('Сумма затрат на рассылку за выбранный период. Учитываются списания бонусов, скидки и затраты на рассылки по всем каналам'),
            align: 'right',
        },
        {
            id: 'date',
            title: t('Дата'),
            align: 'right',
        },
        { id: 'actions' },
    ];
    const rows = convertFlowsToTable({
        flows,
        shouldDisplayProfit,
        currency,
        purchaseUrl,
        openPurchaseFilter,
        actions: (flow) => [
            {
                id: 'view',
                label: t('Просмотр'),
                onClick: () => onEditFlow(flow),
            },
            {
                id: 'edit',
                tooltip: !userHasFlowManage && t('Недоступно для вашей роли'),
                label: t('Редактировать'),
                onClick: () => onEditFlow(flow),
                isDisabled: !userHasFlowManage,
                isSoon: true,
            },
            {
                id: 'delete',
                tooltip: !userHasFlowManage && t('Недоступно для вашей роли'),
                label: t('Распустить'),
                onClick: () => onDeleteFlow(flow),
                isDanger: true,
                isDisabled: !userHasFlowManage,
            },
        ],
    });
    const onRowClick = (row) => {
        const clicked = flows.find((flow) => flow.id === row.id);
        if (clicked)
            onEditFlow(clicked);
    };
    if (!flows.length)
        return _jsx(FlowsTableEmpty, { onCreateFlowClick: onCreateFlow });
    return (_jsx(Table, { className: styles.table, cellClassNames: { 1: styles.channelColumn }, isLoading: isLoading, columns: columns, rows: rows, onRowClick: onRowClick }));
};
