import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useUserPermissions } from '@/app/useUserPermissions';
import { FlowCanvas, FlowCanvasProvider, } from '@/components/flow/FlowCanvas/FlowCanvas';
import { composeMailings } from './composeMailings';
export const AutoMailingsFlow = ({ mailings = [], fitView = false, onMailingClick, }) => {
    const userHasAccessTo = useUserPermissions();
    const { nodes, edges } = composeMailings(mailings);
    const handleNodeClick = useCallback((e, node) => {
        if (node.type === 'channel') {
            const data = node.data;
            const mailing = data.mailing;
            if (mailing && onMailingClick)
                onMailingClick(mailing);
        }
    }, [onMailingClick]);
    return (_jsx(FlowCanvasProvider, { children: _jsx(FlowCanvas, { nodes: nodes, edges: edges, fitView: fitView, onNodeClick: onMailingClick && userHasAccessTo('AUTO_MAILINGS_MANAGE')
                ? handleNodeClick
                : undefined }) }));
};
