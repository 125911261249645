import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { ChannelCircles } from '@/components/ChannelCircles/ChannelCircles';
import { TableExpensesCell as Expenses } from '@/components/TableExpensesCell/TableExpensesCell';
import { TableProfitRevenueCell as ProfitRevenue } from '@/components/TableProfitRevenueCell/TableProfitRevenueCell';
import { cr } from '@/utils/cr';
import { numberRound } from '@/utils/numbers';
import { roi } from '@/utils/roi';
export const convertFlowToRowTable = ({ flow, shouldDisplayProfit, currency, purchaseUrl, openPurchaseFilter, actions, }) => {
    return {
        id: flow.id,
        cells: [
            flow.title,
            _jsx(ChannelCircles, { channels: flow.channels }, 'channels'),
            numberRound(roi(shouldDisplayProfit ? flow.profit : flow.revenue, flow.expenses)) + '%',
            numberRound(cr(flow.goals, flow.sendings), 2) + '%',
            _jsx(ProfitRevenue, { shouldDisplayProfit: shouldDisplayProfit, profit: Number(flow.profit), revenue: Number(flow.revenue), currency: currency, href: purchaseUrl, onClick: () => openPurchaseFilter(flow.mailings) }, 'profitOrRevenue'),
            _jsx(Expenses, { expenses: Number(flow.expenses), currency: currency }, 'expenses'),
            moment(flow.createdAt).toDate(),
        ],
        actions,
    };
};
