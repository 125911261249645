import { t } from 'i18next';
import { checkingForTargetKey } from './checkingForTargetKey';
export const createSpecificationsList = (purchase) => purchase === null || purchase === void 0 ? void 0 : purchase.items.map(({ product = {}, price, sku }) => {
    var _a;
    const additionalItems = {};
    const properties = (product === null || product === void 0 ? void 0 : product.properties) || [];
    properties === null || properties === void 0 ? void 0 : properties.forEach(({ title, value }) => {
        if (checkingForTargetKey(title))
            additionalItems[title.toLowerCase()] = value;
    });
    for (const key in product === null || product === void 0 ? void 0 : product.attributes)
        if (checkingForTargetKey(key))
            additionalItems[key.toLowerCase()] = (_a = product === null || product === void 0 ? void 0 : product.attributes) === null || _a === void 0 ? void 0 : _a[key];
    return {
        title: (product === null || product === void 0 ? void 0 : product.title) || t('без названия'),
        value: [
            ...Object.entries((product === null || product === void 0 ? void 0 : product.attributes) || {}).map(([title, value]) => ({
                title,
                value,
            })),
            ...properties,
        ],
        sku,
        price,
        additionalItems,
    };
});
