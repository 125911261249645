import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation, useParams } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Container } from '@/components/Container/Container';
import { WalletCardForm } from '@/components/wallet-cards/WalletCardForm/WalletCardForm';
import { useLevels } from '../WalletCards/useLevels';
import { WalletCardPublish } from '../WalletCards/WalletCardPublish';
import { useCounters } from './useCounters';
import { useSaveWalletCard } from './useSaveWalletCard';
import { useUploadImage } from './useUploadImage';
import { useWalletCardData } from './useWalletCardData';
export const WalletCardsForm = ({ defaultLevelId, defaultCardId, }) => {
    var _a, _b;
    const { brand, currency } = useCurrentUser();
    const location = useLocation();
    const userHasAccessTo = useUserPermissions();
    const params = useParams();
    const levelId = params.levelId ? Number(params.levelId) : defaultLevelId;
    const cardId = params.cardId ? Number(params.cardId) : defaultCardId;
    const { hasNoLevels } = useLevels();
    const { selectedLevel, defaultValues, isLoading } = useWalletCardData(cardId, levelId);
    const counters = useCounters();
    const uploadImage = useUploadImage();
    const { saveCard } = useSaveWalletCard(cardId, levelId);
    const walletCardActiveType = ((_a = location.state) === null || _a === void 0 ? void 0 : _a.walletCardActiveType) || '';
    const walletCardActiveElement = ((_b = location.state) === null || _b === void 0 ? void 0 : _b.walletCardActiveElement) || '';
    const key = cardId + walletCardActiveType + walletCardActiveElement;
    if (!brand || isLoading)
        return null;
    return (_jsx(Container, { children: _jsx(WalletCardForm, { title: selectedLevel === null || selectedLevel === void 0 ? void 0 : selectedLevel.name, defaultValues: Object.assign({ levelName: hasNoLevels ? '' : selectedLevel === null || selectedLevel === void 0 ? void 0 : selectedLevel.name }, defaultValues), onChange: userHasAccessTo('WALLET_CARDS_MANAGE') ? saveCard : undefined, counters: counters, uploadImage: uploadImage, currency: currency, cardFooter: _jsx(WalletCardPublish, {}) }) }, key));
};
