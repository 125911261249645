import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccentText } from '@/components/AccentText/AccentText';
import { Table } from '@/components/Table/Table';
import { getClientFullName } from '@/utils/getClientFullName';
import { money } from '@/utils/numbers';
import { formatPhoneNumber } from '@/utils/phoneNumber';
import styles from './styles.module.scss';
export const PurchasesTable = memo(({ purchases = [], currency, isLoading, onRowClick }) => {
    const { t } = useTranslation();
    const columns = [
        {
            id: 'executedAt',
            title: t('Дата операции'),
        },
        {
            id: 'phoneNumber',
            title: t('Телефон'),
        },
        {
            id: 'clientName',
            title: t('Имя клиента'),
        },
        {
            id: 'shop',
            title: t('Точка продажи'),
            tooltip: t('Место, где была совершена покупка'),
        },
        {
            id: 'cashier',
            title: t('Сотрудник'),
            tooltip: t('Учетная запись, под которой провели покупку'),
        },
        {
            id: 'paidAmount',
            title: t('Сумма покупки'),
            align: 'right',
        },
        {
            id: 'bonusesApplied',
            title: (_jsxs(Fragment, { children: [_jsx("span", { children: t('Бонусов') }), _jsx("br", {}), _jsx("span", { children: t('списано') })] })),
            align: 'right',
        },
        {
            id: 'bonusesCollected',
            title: (_jsxs(Fragment, { children: [_jsx("span", { children: t('Бонусов') }), _jsx("br", {}), _jsx("span", { children: t('начислено') })] })),
            align: 'right',
        },
    ];
    const rows = purchases.map((purchase) => ({
        id: purchase.id,
        cells: [
            moment(purchase.executedAt).format('DD.MM.YYYY HH:mm'),
            _jsx(Fragment, { children: _jsx("span", Object.assign({ className: styles.nowrap }, { children: formatPhoneNumber(purchase.client.phoneNumber) })) }, 'phoneNumber'),
            getClientFullName(purchase.client),
            purchase.shop.name,
            purchase.cashier || purchase.cashierId,
            money({ input: purchase.paidAmount, currency }),
            _jsx(Fragment, { children: _jsx(AccentText, Object.assign({ accent: purchase.bonusesApplied > 0 ? 'danger' : 'default' }, { children: purchase.bonusesApplied > 0 ? -purchase.bonusesApplied : 0 })) }, 'bonusesApplied'),
            _jsx(Fragment, { children: _jsx(AccentText, Object.assign({ accent: purchase.bonusesCollected > 0 && !(purchase.bonus || {}).isPending
                        ? 'success'
                        : (purchase.bonus || {}).isPending
                            ? 'warning'
                            : 'default' }, { children: purchase.bonusesCollected })) }, 'bonusesCollected'),
        ],
    }));
    return (_jsx(Table, { onRowClick: (_, rowIndex) => {
            if (onRowClick)
                onRowClick(rowIndex);
        }, className: styles.table, isLoading: isLoading, rows: rows, columns: columns, hasEmptyState: true }));
});
