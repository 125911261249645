import { useCallback, useMemo, useState } from 'react';
import { compileFilters } from '@/services/filters/compileFilters';
import { addToLS, getFromLS } from '@/utils/LS';
export const useFiltersState = () => {
    var _a;
    const filtersFromLS = getFromLS({
        key: 'purchaseFilters',
    });
    const [filters, setFilters] = useState(((_a = filtersFromLS === null || filtersFromLS === void 0 ? void 0 : filtersFromLS.filters) === null || _a === void 0 ? void 0 : _a.length) ? filtersFromLS : null);
    const compiledFilters = useMemo(() => compileFilters((filters === null || filters === void 0 ? void 0 : filters.filters) || []), [filters]);
    const updateFilters = useCallback((filters) => {
        if (!filters.length)
            reset();
        else {
            const state = { filters, touched: true };
            setFilters(state);
            addToLS({ key: 'purchaseFilters', value: state });
        }
    }, []);
    const reset = useCallback(() => {
        setFilters(null);
        addToLS({ key: 'purchaseFilters', value: { filters: [], touched: false } });
    }, []);
    return {
        filters,
        compiledFilters,
        updateFilters,
        reset,
    };
};
