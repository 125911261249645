import { t } from 'i18next';
export const createOffersList = (purchase) => {
    const offersObject = ((purchase === null || purchase === void 0 ? void 0 : purchase.offers) || []).map(({ title, purchaseOffers }) => ({
        title,
        value: purchaseOffers.map(({ amount, bonusesCollected, item }) => {
            var _a;
            return ({
                amount: amount,
                productTitle: ((_a = item.product) === null || _a === void 0 ? void 0 : _a.title) || t('без названия'),
                count: item.itemCount,
                bonusesCollected,
            });
        }),
    }));
    // Добавляем сумму скидок всех товаров с конкретной акцией
    const offers = offersObject === null || offersObject === void 0 ? void 0 : offersObject.map((offer) => (Object.assign(Object.assign({}, offer), { discountSum: offer.value.reduce((acc, { amount }) => acc + +amount, 0), isMoney: true, bonusesCollected: offer.value.reduce((acc, { bonusesCollected }) => acc + (bonusesCollected || 0), 0) })));
    return offers;
};
