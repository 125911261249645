import { useTranslation } from 'react-i18next';
import { TRIGGER_TYPE_ABANDONED_CART, TRIGGER_TYPE_PRICE_IN_CART_DROPPED, TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED, } from '@/components/MailingTriggerTypeInput/useOptions';
export const useUnlayerBlocks = (options = {}) => {
    const { t } = useTranslation();
    const { brandName = '', triggerType, includeRecommendations = false, recommendationType, hasWalletFeature = false, } = options;
    const blocks = [];
    blocks.push({
        category: t('Подвал'),
        tags: [t('подвал'), t('отписаться')],
        data: {
            cells: [1],
            columns: [
                {
                    contents: [
                        {
                            type: 'divider',
                            values: {
                                containerPadding: '10px',
                                _meta: {
                                    htmlClassNames: 'u_content_divider',
                                },
                                selectable: true,
                                draggable: true,
                                deletable: true,
                                width: '100%',
                                border: {
                                    borderTopWidth: '1px',
                                    borderTopStyle: 'solid',
                                    borderTopColor: '#BBBBBB',
                                },
                                textAlign: 'center',
                            },
                        },
                        {
                            type: 'text',
                            values: {
                                containerPadding: '10px',
                                _meta: {
                                    htmlClassNames: 'u_content_text',
                                },
                                selectable: true,
                                draggable: true,
                                deletable: true,
                                color: '#000000',
                                textAlign: 'center',
                                lineHeight: '140%',
                                linkStyle: {
                                    body: false,
                                    inherit: false,
                                    linkColor: '#808080',
                                    linkUnderline: true,
                                    linkHoverColor: '#0000ee',
                                    linkHoverUnderline: true,
                                },
                                // FIXME translate
                                text: '<p style="font-size: 12px; color: #808080">' +
                                    'Вы получили это письмо, так как являетесь подписчиком ' +
                                    brandName +
                                    '.<br />' +
                                    'Если вы больше не хотите получать эту рассылку, можно ' +
                                    '<a href="{{ brand.settings.landingBase }}/m/unsub/{{ msgid }}">отписаться</a>.</p>',
                            },
                        },
                    ],
                    values: {
                        _meta: {
                            htmlClassNames: 'u_column',
                        },
                    },
                },
            ],
            values: {
                columns: false,
                backgroundColor: '',
                columnsBackgroundColor: '',
                backgroundImage: {
                    url: '',
                    fullWidth: true,
                    repeat: false,
                    center: true,
                    cover: false,
                },
                padding: '0px',
                hideDesktop: false,
                hideMobile: false,
                noStackMobile: false,
                _meta: {
                    htmlClassNames: 'u_row',
                },
                selectable: true,
                draggable: true,
                deletable: true,
            },
        },
        displayMode: 'email',
    });
    if (hasWalletFeature)
        blocks.push({
            category: t('Карта Wallet'),
            tags: [t('карта'), t('телефон')],
            data: {
                cells: [1],
                columns: [
                    {
                        contents: [
                            {
                                type: 'button',
                                values: {
                                    containerPadding: '10px',
                                    _meta: {
                                        htmlClassNames: 'u_content_button',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    deletable: true,
                                    href: '{{ walletsLink }}',
                                    buttonColors: {
                                        color: '#FFFFFF',
                                        backgroundColor: '#37d67a',
                                        hoverColor: '#FFFFFF',
                                        hoverBackgroundColor: '#3AAEE0',
                                    },
                                    textAlign: 'center',
                                    lineHeight: '130%',
                                    border: {
                                        borderTopWidth: '0px',
                                        borderTopStyle: 'solid',
                                        borderTopColor: '#CCC',
                                        borderLeftWidth: '0px',
                                        borderLeftStyle: 'solid',
                                        borderLeftColor: '#CCC',
                                        borderRightWidth: '0px',
                                        borderRightStyle: 'solid',
                                        borderRightColor: '#CCC',
                                        borderBottomWidth: '0px',
                                        borderBottomStyle: 'solid',
                                        borderBottomColor: '#CCC',
                                    },
                                    borderRadius: '6px',
                                    padding: '10px 20px',
                                    // FIXME translate
                                    text: '<span style="font-size: 24px; line-height: 31.2px; font-family: helvetica, sans-serif;">' +
                                        '&hearts;️📲&nbsp;<span style="font-size: 20px; line-height: 26px;">Установить карту</span></span>',
                                    calculatedWidth: 264,
                                    calculatedHeight: 51,
                                },
                            },
                        ],
                        values: {
                            _meta: {
                                htmlClassNames: 'u_column',
                            },
                        },
                    },
                ],
                values: {
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: false,
                        center: true,
                        cover: false,
                    },
                    padding: '0px',
                    hideDesktop: false,
                    hideMobile: false,
                    noStackMobile: false,
                    _meta: {
                        htmlClassNames: 'u_row',
                    },
                    selectable: true,
                    draggable: true,
                    deletable: true,
                },
            },
            displayMode: 'email',
        });
    blocks.push({
        category: t('Мобильные приложения'),
        tags: [],
        data: {
            cells: [1],
            columns: [
                {
                    contents: [
                        {
                            type: 'html',
                            values: {
                                containerPadding: '10px',
                                _meta: {
                                    htmlClassNames: 'u_content_html',
                                },
                                selectable: true,
                                draggable: true,
                                deletable: true,
                                // FIXME translate
                                html: '' +
                                    '<table style="margin: auto;" cellpadding="5">\n' +
                                    '<tr>\n' +
                                    '<!--\n' +
                                    'В строке ниже вставьте ссылки на ваши приложения\n' +
                                    'в App Store или Google Play между кавычками, \n' +
                                    'чтобы получилось следующее:\n' +
                                    '\n' +
                                    '<td><a href="https://itunes.apple.com/..." ...\n' +
                                    '<td><a href="https://play.google.com/..." ...\n' +
                                    '-->\n' +
                                    '<td><a href=""><img src="http://s.maxma.com/m/buttons/app/AppStore.png" height="40" width="118" /></a></td>\n' +
                                    '<td><a href=""><img src="http://s.maxma.com/m/buttons/app/GooglePlay.png" height="40" width="135" /></a></td>\n' +
                                    '</tr>\n' +
                                    '</table>',
                            },
                        },
                    ],
                    values: {
                        _meta: {
                            htmlClassNames: 'u_column',
                        },
                    },
                },
            ],
            values: {
                columns: false,
                backgroundColor: '',
                columnsBackgroundColor: '',
                backgroundImage: {
                    url: '',
                    fullWidth: true,
                    repeat: false,
                    center: true,
                    cover: false,
                },
                padding: '0px',
                hideDesktop: false,
                hideMobile: false,
                noStackMobile: false,
                _meta: {
                    htmlClassNames: 'u_row',
                },
                selectable: true,
                draggable: true,
                deletable: true,
            },
        },
        displayMode: 'email',
    });
    if (triggerType == TRIGGER_TYPE_ABANDONED_CART)
        blocks.push({
            category: t('Товары по триггеру (брошенная корзина)'),
            tags: [t('товары')],
            data: {
                cells: [1, 1],
                columns: [
                    {
                        contents: [
                            {
                                type: 'image',
                                values: {
                                    containerPadding: '10px',
                                    src: {
                                        url: '!КартинкаТовара',
                                        width: 800,
                                        height: 200,
                                    },
                                    textAlign: 'center',
                                    altText: '',
                                    action: {
                                        name: 'web',
                                        values: {
                                            href: '!СсылкаНаТовар',
                                            target: '_blank',
                                        },
                                        attrs: {
                                            href: '{{href}}',
                                            target: '{{target}}',
                                        },
                                    },
                                    hideDesktop: false,
                                    _meta: {
                                        htmlClassNames: 'u_content_image',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '',
                            padding: '0px',
                            border: {},
                            _meta: {
                                htmlClassNames: 'u_column',
                            },
                        },
                    },
                    {
                        contents: [
                            {
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    textAlign: 'left',
                                    lineHeight: '140%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    _meta: {
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="font-size: 14px; line-height: 140%; text-align: left;"><span style="font-size: 18px; line-height: 25.2px;">!НазваниеТовара</span></p>\n' +
                                        '<p style="font-size: 14px; line-height: 140%; text-align: left;">!ЦенаТовара руб.</p>',
                                },
                            },
                            {
                                type: 'button',
                                values: {
                                    containerPadding: '10px',
                                    href: {
                                        name: 'web',
                                        values: {
                                            href: '!СсылкаНаТовар',
                                            target: '_blank',
                                        },
                                        attrs: {
                                            href: '{{href}}',
                                            target: '{{target}}',
                                        },
                                    },
                                    buttonColors: {
                                        color: '#FFFFFF',
                                        backgroundColor: '#3AAEE0',
                                        hoverColor: '#FFFFFF',
                                        hoverBackgroundColor: '#3AAEE0',
                                    },
                                    size: {
                                        autoWidth: true,
                                        width: '100%',
                                    },
                                    textAlign: 'left',
                                    lineHeight: '120%',
                                    padding: '10px 20px',
                                    border: {},
                                    borderRadius: '4px',
                                    hideDesktop: false,
                                    _meta: {
                                        htmlClassNames: 'u_content_button',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="font-size: 14px; line-height: 16.8px;">Купить</span>',
                                    calculatedWidth: 92,
                                    calculatedHeight: 37,
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '',
                            padding: '0px',
                            border: {},
                            _meta: {
                                htmlClassNames: 'u_column',
                            },
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: false,
                        center: true,
                        cover: false,
                    },
                    padding: '0px',
                    hideDesktop: false,
                    _meta: {
                        htmlClassNames: 'u_row',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    mergeTagGroup: 'products',
                    mergeTagRule: 'repeat',
                },
            },
            displayMode: 'email',
        });
    if (triggerType == TRIGGER_TYPE_PRICE_IN_CART_DROPPED ||
        triggerType == TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED)
        blocks.push({
            category: t('Товары по триггеру (сниженная цена)'),
            tags: [t('товары')],
            data: {
                cells: [1, 1],
                columns: [
                    {
                        contents: [
                            {
                                type: 'image',
                                values: {
                                    containerPadding: '10px',
                                    src: {
                                        url: '!КартинкаТовара',
                                        width: 800,
                                        height: 200,
                                    },
                                    textAlign: 'center',
                                    altText: '',
                                    action: {
                                        name: 'web',
                                        values: {
                                            href: '!СсылкаНаТовар',
                                            target: '_blank',
                                        },
                                        attrs: {
                                            href: '{{href}}',
                                            target: '{{target}}',
                                        },
                                    },
                                    hideDesktop: false,
                                    _meta: {
                                        htmlClassNames: 'u_content_image',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '',
                            padding: '0px',
                            border: {},
                            _meta: {
                                htmlClassNames: 'u_column',
                            },
                        },
                    },
                    {
                        contents: [
                            {
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    textAlign: 'left',
                                    lineHeight: '140%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    _meta: {
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="font-size: 14px; line-height: 140%; text-align: left;"><span style="font-size: 18px; line-height: 25.2px;">!НазваниеТовара</span></p>\n' +
                                        '<p style="font-size: 14px; line-height: 140%; text-align: left;"><span style="text-decoration: line-through; font-size: 14px; line-height: 19.6px;">!СтараяЦенаТовара руб.</span> !ЦенаТовара руб.</p>',
                                },
                            },
                            {
                                type: 'button',
                                values: {
                                    containerPadding: '10px',
                                    href: {
                                        name: 'web',
                                        values: {
                                            href: '!СсылкаНаТовар',
                                            target: '_blank',
                                        },
                                        attrs: {
                                            href: '{{href}}',
                                            target: '{{target}}',
                                        },
                                    },
                                    buttonColors: {
                                        color: '#FFFFFF',
                                        backgroundColor: '#3AAEE0',
                                        hoverColor: '#FFFFFF',
                                        hoverBackgroundColor: '#3AAEE0',
                                    },
                                    size: {
                                        autoWidth: true,
                                        width: '100%',
                                    },
                                    textAlign: 'left',
                                    lineHeight: '120%',
                                    padding: '10px 20px',
                                    border: {},
                                    borderRadius: '4px',
                                    hideDesktop: false,
                                    _meta: {
                                        htmlClassNames: 'u_content_button',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="font-size: 14px; line-height: 16.8px;">Купить</span>',
                                    calculatedWidth: 92,
                                    calculatedHeight: 37,
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '',
                            padding: '0px',
                            border: {},
                            _meta: {
                                htmlClassNames: 'u_column',
                            },
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: false,
                        center: true,
                        cover: false,
                    },
                    padding: '0px',
                    hideDesktop: false,
                    _meta: {
                        htmlClassNames: 'u_row',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    mergeTagGroup: 'products',
                    mergeTagRule: 'repeat',
                },
            },
            displayMode: 'email',
        });
    if (includeRecommendations)
        blocks.push({
            category: t('Рекомендуемые товары') +
                ' (' +
                (recommendationType == 1 ? t('новинки') : '') +
                (recommendationType == 2 ? t('последние просмотренные') : '') +
                (recommendationType == 3 ? t('бестселлеры') : '') +
                ')',
            tags: [t('товары')],
            data: {
                cells: [1, 1],
                columns: [
                    {
                        contents: [
                            {
                                type: 'image',
                                values: {
                                    containerPadding: '10px',
                                    src: {
                                        url: '!КартинкаТовара',
                                        width: 800,
                                        height: 200,
                                    },
                                    textAlign: 'center',
                                    altText: '',
                                    action: {
                                        name: 'web',
                                        values: {
                                            href: '!СсылкаНаТовар',
                                            target: '_blank',
                                        },
                                        attrs: {
                                            href: '{{href}}',
                                            target: '{{target}}',
                                        },
                                    },
                                    hideDesktop: false,
                                    _meta: {
                                        htmlClassNames: 'u_content_image',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '',
                            padding: '0px',
                            border: {},
                            _meta: {
                                htmlClassNames: 'u_column',
                            },
                        },
                    },
                    {
                        contents: [
                            {
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    textAlign: 'left',
                                    lineHeight: '140%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    _meta: {
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="font-size: 14px; line-height: 140%; text-align: left;"><span style="font-size: 18px; line-height: 25.2px;">!НазваниеТовара</span></p>\n' +
                                        '<p style="font-size: 14px; line-height: 140%; text-align: left;">!ЦенаТовара руб.</p>',
                                },
                            },
                            {
                                type: 'button',
                                values: {
                                    containerPadding: '10px',
                                    href: {
                                        name: 'web',
                                        values: {
                                            href: '!СсылкаНаТовар',
                                            target: '_blank',
                                        },
                                        attrs: {
                                            href: '{{href}}',
                                            target: '{{target}}',
                                        },
                                    },
                                    buttonColors: {
                                        color: '#FFFFFF',
                                        backgroundColor: '#3AAEE0',
                                        hoverColor: '#FFFFFF',
                                        hoverBackgroundColor: '#3AAEE0',
                                    },
                                    size: {
                                        autoWidth: true,
                                        width: '100%',
                                    },
                                    textAlign: 'left',
                                    lineHeight: '120%',
                                    padding: '10px 20px',
                                    border: {},
                                    borderRadius: '4px',
                                    hideDesktop: false,
                                    _meta: {
                                        htmlClassNames: 'u_content_button',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="font-size: 14px; line-height: 16.8px;">Купить</span>',
                                    calculatedWidth: 92,
                                    calculatedHeight: 37,
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '',
                            padding: '0px',
                            border: {},
                            _meta: {
                                htmlClassNames: 'u_column',
                            },
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: false,
                        center: true,
                        cover: false,
                    },
                    padding: '0px',
                    hideDesktop: false,
                    _meta: {
                        htmlClassNames: 'u_row',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    mergeTagGroup: 'recommendedProducts',
                    mergeTagRule: 'repeat',
                },
            },
            displayMode: 'email',
        });
    return blocks;
};
