import { useGetAutoMailingListQuery } from '@/api/query/mailings';
import { useUserPermissions } from '@/app/useUserPermissions';
import { getPresetPeriod, PresetPeriod } from '@/utils/getPresetPeriod';
export const useEmailMailings = (currentMailingId) => {
    const userHasAccessTo = useUserPermissions();
    const [start, end] = getPresetPeriod(PresetPeriod.AllTime);
    const { data = [] } = useGetAutoMailingListQuery({ periodStart: start.format(), periodEnd: end.format() }, { skip: !userHasAccessTo('AUTO_MAILINGS_LIST') });
    const emailMailings = data
        .filter((mailing) => mailing.channels.email)
        .filter((mailing) => mailing.id !== Number(currentMailingId));
    return emailMailings;
};
