var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useFetchFlowCandidatesListMutation } from '@/api/query/flows';
import { TRIGGER_TYPE_EMAIL_DELIVERED } from '@/components/MailingTriggerTypeInput/useOptions';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
import { setModalLoading } from '@/utils/setModalLoading';
export const useFlowCandidates = () => {
    const [mailings, setMailings] = useState([]);
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const [fetchFlowCandidatesList] = useFetchFlowCandidatesListMutation();
    const getFlowCandidates = () => __awaiter(void 0, void 0, void 0, function* () {
        setModalLoading(true);
        try {
            const result = yield fetchFlowCandidatesList({ periodStart, periodEnd });
            const mailings = !('error' in result) ? result.data : [];
            setMailings(mailings.filter((m) => m.triggerType !== TRIGGER_TYPE_EMAIL_DELIVERED));
        }
        finally {
            setModalLoading(false);
        }
    });
    return { mailings, getFlowCandidates };
};
