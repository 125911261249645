import { useLocation, useParams } from 'react-router-dom';
import { useGetFlowQuery } from '@/api/query/flows';
import { useGetFlowCandidatesListQuery } from '@/api/query/flows';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useData = () => {
    var _a, _b;
    const params = useParams();
    const location = useLocation();
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const flowId = params.flowId ? Number(params.flowId) : undefined;
    const trigger = (_a = location.state) === null || _a === void 0 ? void 0 : _a.trigger;
    const mailingIds = (_b = location.state) === null || _b === void 0 ? void 0 : _b.mailingIds;
    const { data: selectedFlow, isFetching: isLoadingSelectedFlow } = useGetFlowQuery({ id: flowId || 0 }, { skip: !flowId });
    const { data: allMailings = [], isFetching: isLoadingMailings } = useGetFlowCandidatesListQuery({ periodStart, periodEnd });
    const isLoading = isLoadingSelectedFlow || isLoadingMailings;
    const mailings = selectedFlow
        ? selectedFlow.mailings
        : mailingIds
            ? collectMailingsForDraft(allMailings, mailingIds)
            : [];
    return {
        selectedFlow,
        triggerType: selectedFlow ? selectedFlow.triggerType : trigger || 0,
        mailings,
        isLoading,
    };
};
const collectMailingsForDraft = (allMailings, mailingIds) => {
    const result = [];
    for (let i = 0; i < allMailings.length; i += 1) {
        const mailing = allMailings[i];
        if (mailingIds.includes(mailing.id)) {
            const followedMailings = allMailings.filter((m) => m.followedMailingBrandId === mailing.id);
            result.push(mailing, ...followedMailings);
        }
    }
    return result;
};
